import { Environment } from "./environmentProperties";

export const environment: Environment = {
    production: true,
    appInsights: {
      instrumentationKey: 'e462a6a9-c5be-4b24-8ef9-e38fa8d5e2a2',
      cloudRoleName: 'mup-ng-ui-qa',
      connectionString: 'InstrumentationKey=e462a6a9-c5be-4b24-8ef9-e38fa8d5e2a2;IngestionEndpoint=https://northcentralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northcentralus.livediagnostics.monitor.azure.com/'
    },
    apiEndPoint: 'https://qaapi.portal.meridianitinc.com',
    authSettings: {
        authority: 'https://qalogin.portal.meridianitinc.com',
        post_logout_uri: 'https://qa.portal.meridianitinc.com/logout',
        redirect_uri: 'https://qa.portal.meridianitinc.com'
    },
    azureAd: {
      clientId: 'bfa0d268-79e7-4a82-83de-89054568c60f',
      tenatId: 'c55fab4a-533e-46bb-a450-768de6e9d931',
      redirectUri: 'https://qa.portal.meridianitinc.com',
    },
    protectedResources: [
      {
        name: 'portalApi',
        resourceUri: 'https://qaapi.portal.meridianitinc.com',
        resourceScopes: ['api://54d7d517-d6d8-4da5-a180-ed0e85d09e77/ApiAccess']
      },
      {
        name: 'graphApi',
        resourceUri: 'https://graph.microsoft.com',
        resourceScopes: [
          'https://graph.microsoft.com/Application.ReadWrite.All',
          'https://graph.microsoft.com/AppRoleAssignment.ReadWrite.All',
          'https://graph.microsoft.com/User.ReadWrite.All',
          'https://graph.microsoft.com/RoleManagement.ReadWrite.Directory'
        ]
      }
    ],
  enterpriseApplicationId: '32bfb558-5c65-4c75-879f-f9515e5d2bad',
  applicationId: 'a509fe2a-5383-4001-9faf-1981e4bf72c2'
};
