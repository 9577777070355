<mat-card>
  <div style="position: relative" *ngIf="!isLoading">
    <!-- Button positioned in the top-right corner -->
    <button
      mat-raised-button
      color="primary"
      style="position: absolute; top: 10px; right: 10px"
      (click)="openQuoteExportPopup()"
    >
      Export Internal Quote
    </button>
    <mat-card-content *ngIf="project">
      <p
        class="red bold"
        *ngIf="
          project.client.clientRates !== null &&
          project.client.clientRates.length > 0
        "
      >
        This client has special pricing
      </p>
      <p class="red bold" *ngIf="project.client.customSowTemplate">
        This client has a special SOW template
      </p>
      <p class="red bold" *ngIf="project.client.notes?.length > 0">
        {{ project.client.notes }}
      </p>
      <h3>Project Details</h3>
      <p>Project Name: {{ project.projectName }}</p>
      <p>Client Name: {{ project.client.commonName }}</p>
      <p>BF Number: {{ project.oppurtunityNumber }}</p>
      <p>Created Date: {{ project.createdDate | date : "shortDate" }}</p>
      <h3>Service Estimate (SE)</h3>
      <div>
        <button
          *ngIf="!isSales"
          mat-raised-button
          color="primary"
          matTooltip="Multiple Service Estimates can be created by selecting button multiple times"
          [routerLink]="['createServiceEstimate']"
        >
          Create Service Estimate(SE)
        </button>
        <mat-table #table [dataSource]="dataSource">
          <ng-container matColumnDef="projectName">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="updatedDate">
            <mat-header-cell *matHeaderCellDef> Last Updated </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.updatedDate | date : "shortDate" }}
              <small>
                {{ element.version ? " .v " + element.version : "" }}</small
              ></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="createdDate">
            <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.createdDate | date : "shortDate" }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="approvedDate">
            <mat-header-cell *matHeaderCellDef> Approved Date </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.approvedDate | date : "shortDate" }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="mileStone">
            <mat-header-cell
              *matHeaderCellDef
              (click)="sortByMilestone()"
              class="clickable"
            >
              Milestone <mat-icon class="twelve"> sort </mat-icon>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              class="append-color"
              (click)="fetchSEMilestones(element.milestone)"
            >
              {{ element.milestone }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expirationDate">
            <mat-header-cell *matHeaderCellDef>
              Expiration Date
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.expirationDate | date : "shortDate" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="seApprover">
            <mat-header-cell *matHeaderCellDef> SE Approver </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.serviceEstimateStatus == '2'; else approversTemplate">
                {{ element.serviceEstimateApprover?.firstName }} {{ element.serviceEstimateApprover?.lastName }}
              </ng-container>
              <ng-template #approversTemplate>
                {{ element.approvers }}
              </ng-template>
            </mat-cell>
          </ng-container>
          

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.serviceEstimateStatus == 0"
                >Components still in progress</span
              >
              <span *ngIf="element.serviceEstimateStatus == 1"
                >Pending Director Approval/Override</span
              >
              <span *ngIf="element.serviceEstimateStatus == 2">Approved</span>
              <span *ngIf="element.serviceEstimateStatus == 3">Expired</span>
              <span *ngIf="element.serviceEstimateStatus == 4"
                >Contract Signed</span
              >
              <span *ngIf="element.serviceEstimateStatus == 5"
                >Pending PM Approval/Override</span
              >
              <span *ngIf="element.serviceEstimateStatus == 6"
                >Pending VP Threshold Approval</span
              >
              <span *ngIf="element.serviceEstimateStatus == 7"
                >SE needs submission</span
              >
              <span *ngIf="element.serviceEstimateStatus == 8"
                >Component Pending Rate Approval</span
              >
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="componentDetails">
            <mat-header-cell *matHeaderCellDef> Component Details </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button (click)="openComponentDetailsPopup(element)">View</button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span
                *ngFor="
                  let seTag of element.serviceEstimateTags;
                  first as isFirst
                "
              >
                <span *ngIf="!isFirst">, </span
                ><span> {{ seTag.tag.name }}</span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                id="kebabMenu"
                [attr.data-id]="element.serviceEstimateId"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  color="default"
                  type="button"
                  [routerLink]="[
                    'editServiceEstimate',
                    element.serviceEstimateId
                  ]"
                  *ngIf="!isSales"
                >
                  <mat-icon>create</mat-icon>
                  <span>Edit</span>
                </button>
                <button
                  mat-menu-item
                  color="warn"
                  type="button"
                  (click)="deleteRecord(element.serviceEstimateId)"
                  *ngIf="element.serviceEstimateStatus != 4"
                  [disabled]="!showDeleteAction"
                >
                  <mat-icon>remove</mat-icon>
                  <span>Delete</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  type="button"
                  (click)="cloneRecord(element.serviceEstimateId)"
                >
                  <mat-icon>file_copy</mat-icon>
                  <span>Clone</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  type="button"
                  [routerLink]="['seDetails', element.serviceEstimateId]"
                  *ngIf="!isSales"
                >
                  <mat-icon>list</mat-icon>
                  <span>Components</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  type="button"
                  [routerLink]="[
                    'seDetails',
                    element.serviceEstimateId,
                    'summary'
                  ]"
                  *ngIf="
                    element.serviceEstimateStatus == 2 ||
                    element.serviceEstimateStatus == 4
                  "
                >
                  <mat-icon>info</mat-icon>
                  <span>Summary</span>
                </button>
                <button
                  mat-menu-item
                  type="button"
                  color="primary"
                  *ngIf="!isSales"
                  (click)="markContractSigned(element)"
                  [disabled]="
                    element.serviceEstimateStatus != 2 || !isAbleToSignContract
                  "
                >
                  <mat-icon>date_range</mat-icon>
                  <span>Mark Contract Signed</span>
                </button>
                <button
                  mat-menu-item
                  type="button"
                  color="primary"
                  *ngIf="isAbleToUpdateContractDate"
                  (click)="markContractSigned(element)"
                  [disabled]="element.serviceEstimateStatus != 4"
                >
                  <mat-icon class="rotate-icon">autorenew</mat-icon>
                  <span>Update Contract Signed</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            id="seTable"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
        </mat-table>

        <h3>Change Orders</h3>
        <button
          mat-raised-button
          [routerLink]="['createChangeOrder']"
          [disabled]="isSales"
          color="primary"
        >
          Create Change Order
        </button>
        <mat-table #table [dataSource]="changeOrderDataSource">
          <ng-container matColumnDef="projectName">
            <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="updatedDate">
            <mat-header-cell *matHeaderCellDef> Last Updated </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.updatedDate | date : "shortDate" }}
              <small>
                {{ element.version ? " .v " + element.version : "" }}</small
              ></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="createdDate">
            <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.createdDate | date : "shortDate" }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="approvedDate">
            <mat-header-cell *matHeaderCellDef> Approved Date </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.approvedDate | date : "shortDate" }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="mileStone">
            <mat-header-cell
              *matHeaderCellDef
              (click)="changeOrderSortByMilestone()"
              class="clickable"
            >
              Milestone <mat-icon class="twelve"> sort </mat-icon>
            </mat-header-cell>
            <mat-cell
              *matCellDef="let element"
              class="append-color"
              (click)="fetchSEMilestones(element.milestone)"
            >
              {{ element.milestone }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="expirationDate">
            <mat-header-cell *matHeaderCellDef>
              Expiration Date
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{ element.expirationDate | date : "shortDate" }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="seApprover">
            <mat-header-cell *matHeaderCellDef> SE Approver </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.serviceEstimateStatus == '2'; else approversTemplate">
                {{ element.serviceEstimateApprover?.firstName }} {{ element.serviceEstimateApprover?.lastName }}
              </ng-container>
              <ng-template #approversTemplate>
                {{ element.approvers }}
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="element.serviceEstimateStatus == 0"
                >Components still in progress</span
              >
              <span *ngIf="element.serviceEstimateStatus == 1"
                >Pending Director Approval/Override</span
              >
              <span *ngIf="element.serviceEstimateStatus == 2">Approved</span>
              <span *ngIf="element.serviceEstimateStatus == 3">Expired</span>
              <span *ngIf="element.serviceEstimateStatus == 4"
                >Contract Signed</span
              >
              <span *ngIf="element.serviceEstimateStatus == 5"
                >Pending PM Approval/Override</span
              >
              <span *ngIf="element.serviceEstimateStatus == 6"
                >Pending VP Threshold Approval</span
              >
              <span *ngIf="element.serviceEstimateStatus == 7"
                >SE needs submission</span
              >
              <span *ngIf="element.serviceEstimateStatus == 8"
                >Component Pending Rate Approval</span
              >
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="componentDetails">
            <mat-header-cell *matHeaderCellDef> Component Details </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button mat-button (click)="openComponentDetailsPopup(element)">View</button>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef> Tags </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span
                *ngFor="
                  let seTag of element.serviceEstimateTags;
                  first as isFirst
                "
              >
                <span *ngIf="!isFirst">, </span
                ><span> {{ seTag.tag.name }}</span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                id="changeKebabMenu"
                [attr.data-id]="element.serviceEstimateId"
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  color="default"
                  type="button"
                  [routerLink]="['editChangeOrder', element.serviceEstimateId]"
                  *ngIf="!isSales"
                >
                  <mat-icon>create</mat-icon>
                  <span>Edit</span>
                </button>
                <button
                  mat-menu-item
                  color="warn"
                  type="button"
                  (click)="deleteRecord(element.serviceEstimateId)"
                  *ngIf="element.serviceEstimateStatus != 4"
                  [disabled]="!showDeleteAction"
                >
                  <mat-icon>remove</mat-icon>
                  <span>Delete</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  type="button"
                  (click)="cloneRecord(element.serviceEstimateId, true)"
                >
                  <mat-icon>file_copy</mat-icon>
                  <span>Clone</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  type="button"
                  [routerLink]="['seDetails', element.serviceEstimateId]"
                  *ngIf="!isSales"
                >
                  <mat-icon>list</mat-icon>
                  <span>Components</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  type="button"
                  [routerLink]="[
                    'seDetails',
                    element.serviceEstimateId,
                    'summary'
                  ]"
                  *ngIf="
                    element.serviceEstimateStatus == 2 ||
                    element.serviceEstimateStatus == 4
                  "
                >
                  <mat-icon>info</mat-icon>
                  <span>Summary</span>
                </button>
                <button
                  mat-menu-item
                  type="button"
                  color="primary"
                  *ngIf="!isSales"
                  (click)="markContractSigned(element)"
                  [disabled]="
                    element.serviceEstimateStatus != 2 || !isAbleToSignContract
                  "
                >
                  <mat-icon>date_range</mat-icon>
                  <span>Mark Contract Signed</span>
                </button>
                <button
                  mat-menu-item
                  type="button"
                  color="primary"
                  *ngIf="isAbleToUpdateContractDate"
                  (click)="markContractSigned(element)"
                  [disabled]="element.serviceEstimateStatus != 4"
                >
                  <mat-icon class="rotate-icon">autorenew</mat-icon>
                  <span>Update Contract Signed</span>
                </button>
              </mat-menu>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            id="orderTable"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
        </mat-table>
      </div>
      <mat-spinner
        style="text-align: center; margin: 0 auto"
        *ngIf="isLoading"
      ></mat-spinner>
    </mat-card-content>
  </div>
</mat-card>
