<mat-card>
    <mat-card-content>
        <form [formGroup]="marginOptionsFormGroup" #form="ngForm" (ngSubmit)="submitForm()" *ngIf="marginOptionsFormGroup">
            <mat-card-content>        
                <div class="col-md-2 version-form-field">
                    <div class="row">
                        <mat-radio-group formControlName="marginOptionType" class="col-md-7 radio-group" name="marginOptionType" id="marginOptionType" >
                        
                            <mat-radio-button class="radio-button align-bottom" value="uplift">Uplift %</mat-radio-button>               
                            <mat-radio-button class="radio-button" value="grossMargin">Gross Margin %</mat-radio-button>
                        </mat-radio-group>
                        <div class="col-md-5">
                            <mat-form-field [class.isVisible]="marginOptionsFormGroup.controls['marginOptionType'].value!='uplift'">
                                <input matInput currencyMask placeholder="Enter a Value" formControlName="upliftPercentage" id="upliftPercentage" />
                                <span matSuffix>%</span>
                                <mat-error *ngIf="marginOptionsFormGroup.controls['upliftPercentage'].hasError('required')">Please enter a valid uplift%</mat-error>
                            </mat-form-field>
                            <mat-form-field [class.isVisible]="marginOptionsFormGroup.controls['marginOptionType'].value!='grossMargin'">
                                <input matInput currencyMask  placeholder="Enter a Value" formControlName="grossMarginPercentage" id="grossMarginPercentage">
                                <mat-error *ngIf="marginOptionsFormGroup.controls['grossMarginPercentage'].hasError('required')">Please enter a valid gross margin%</mat-error>
                            </mat-form-field>
                        </div>
                    </div>               
                  
                </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button type="submit" [disabled]="!marginOptionsFormGroup.valid" id="btnSubmit">Save</button>
              <!-- <button mat-raised-button type="button" (click)="goBack()" id="btnCancel">Cancel</button> -->
            </mat-card-actions>
          </form>
    </mat-card-content>
  </mat-card>
  