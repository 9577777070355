import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
  NgForm,
  UntypedFormControl,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import {
  ServiceEstimateDetail,
  ProjectPhase,
} from '../../models/service-estimate';
import { ServiceRole } from '../../../admin/models/service-role';
import { ServiceRoleService } from '../../../admin/service-role/service-role.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BusinessPartnerSearchPopupComponent } from '../../../business-partners/business-partner-search-popup/business-partner-search-popup.component';
import { ServiceEstimatePopupComponent } from '../../../shared/components/service-estimate-popup/service-estimate-popup.component';
import { ServiceEstimateComponentPopupComponent } from '../../../shared/components/service-estimate-component-popup/service-estimate-component-popup.component';
import { SolutionPilar } from '../../../business-partners/models/solution-pilar';
import { BusinessPartnerService } from '../../../business-partners/business-partner.service';
import { ActivatedRoute } from '@angular/router';
import { ProfessionalServicesService } from '../../professional-services.service';
import { Location } from '@angular/common';
import { MaterialItemService } from '../../material-item.service';
import { ServiceEstimatorService } from '../../_services/service-estimator.service';
import {
  debounceTime,
  map,
  tap,
  distinctUntilChanged,
  startWith,
} from 'rxjs/operators';

import { nonZeroValidator } from '../../../shared/validators/non-zero-validator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ConfirmComponentChangesComponent } from '../../../shared/components/confirm-component-changes/confirm-component-changes.component';
import { Tag } from '../../../tags/models/tag.model';
import { TagsService } from '../../../tags/tags.service';
import { TagsPopupComponent } from '../../../shared/components/tags-popup/tags-popup.component';
import { TagComponent } from '../../../shared/components/tag/tag.component';
import { CloningPopupComponent } from '../../../shared/components/cloning-popup/cloning-popup.component';
import { MarginAdjustmentsService } from 'src/app/admin/margin-adjustments/margin-adjustments.service';
import { stepValidator } from '../../service-estimates/parent-estimate-create/parent-estimate-create.component';
import {
  MarginOption,
  MarginOptionType,
} from 'src/app/admin/margin-adjustments/models/marginOption';
import { LocationService } from 'src/app/shared/services/location.service';

@Component({
  selector: 'app-professional-services-estimate-create',
  templateUrl: './professional-services-estimate-create.component.html',
  styleUrls: ['./professional-services-estimate-create.component.scss'],
})
export class ProfessionalServicesEstimateCreateComponent implements OnInit {
  @ViewChild('donwloadCsvLink', { static: true })
  private donwloadCsvLink: ElementRef;

  serviceEstimateForm: UntypedFormGroup;
  serviceEstimateItems: Array<any>;
  serviceRoles: Array<ServiceRole>;
  serviceEstimateDetailsPlanning: UntypedFormArray;
  serviceEstimateDetailsExecute: UntypedFormArray;
  serviceEstimateDetailsClosing: UntypedFormArray;
  businessPartnerItems: UntypedFormArray;
  travelExpenses: UntypedFormArray;
  skuItems: UntypedFormArray;
  materialItems: UntypedFormArray;
  isValid = true;
  pricingNotes: Array<any>;
  isNotesRequired = false;
  isSaving = false;
  planningPhase = ProjectPhase.Plan;
  solutionPilars: Array<SolutionPilar>;
  skuSolPilars: Array<SolutionPilar>;
  seId: string;
  secId: string;
  travelExpenseGroup: UntypedFormGroup;
  totalHours: number;
  totalCost: number;
  serviceEstimate: any;
  sec: any;
  totalPrevailingWageHrs: number;
  totalTravelExpenseHrs: number;
  totalBPHrs: number;
  @ViewChild('tag') tagComponent: TagComponent;
  tagItems: UntypedFormArray;
  tags: Array<Tag>;
  actualTags: Array<Tag>;
  isSelectOpened: boolean;
  isRemoveBtnDisabled: boolean;
  isNotValidGrossMarginPerct: boolean;
  serviceEstimateType: number;
  businessPartners: Array<any>;
  engagementType: Array<string>;
  travelExpenseTypes: Array<string>;
  materialItemsExpenseTypes: Array<string>;
  selectableBusinessPartners: Array<any>;
  selectedServiceRole: any;
  marginOption: any;
  isUpliftDefaulted: boolean;
  prevailingWage: UntypedFormGroup;
  counties: Array<any>;
  states: Array<any>;

  @ViewChild('form')
  form: NgForm;
  constructor(
    private fb: UntypedFormBuilder,
    private serviceRolesService: ServiceRoleService,
    private businessPartnerService: BusinessPartnerService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private psService: ProfessionalServicesService,
    private serviceEstimatiorService: ServiceEstimatorService,
    private materialService: MaterialItemService,
    private location: Location,
    private _snackBar: MatSnackBar,
    private tagsService: TagsService,
    private marginAdjustmentsService: MarginAdjustmentsService,
    private locationService: LocationService
  ) {}

  ngOnInit() {
    this.totalHours = 0;
    this.totalPrevailingWageHrs = 0;
    this.totalTravelExpenseHrs = 0;
    this.totalBPHrs = 0;
    this.isNotValidGrossMarginPerct = false;
    this.locationService.getStates().subscribe((res) => {
      this.states = res.sort((a, b) =>
        a.abbreviation.length > b.abbreviation.length ? -1 : 1
      );
    });
    this.engagementType = [
      'Professional Services - Implementations, Installations, Upgrades',
      'Managed Services',
      'Consulting Services - Technical Information, Instruction without touching environments',
    ];
    this.travelExpenseTypes = ['Meridian', 'Business Partner'];
    this.materialItemsExpenseTypes = ['Meridian', 'Business Partner'];
    this.route.params.subscribe((param) => {
      this.seId = param['serviceEstimateId'];
      this.secId = param['secId'];
      this.psService.getSE(this.seId, false).subscribe((res) => {
        this.serviceEstimate = res;
        this.serviceEstimateType = this.serviceEstimate.serviceEstimateType;
      });
      this.serviceEstimateDetailsPlanning = this.fb.array([]);
      this.serviceEstimateDetailsClosing = this.fb.array([]);
      this.serviceEstimateDetailsExecute = this.fb.array([]);
      this.businessPartnerItems = this.fb.array([]);
      this.travelExpenses = this.fb.array([]);
      this.skuItems = this.fb.array([]);
      this.materialItems = this.fb.array([]);
      this.tagItems = this.fb.array([]);
      this.getMarginOptions();

      this.serviceRolesService
        .getAllServiceRolesBySeId(this.seId)
        .subscribe((res) => {
          this.setServiceRoles(res);
          this.tagsService.getTags().subscribe((res) => {
            this.tags = res;
            this.actualTags = Object.assign([], this.tags);
          });

          this.businessPartnerService.getSolutionPilars().subscribe(
            (pilarResult) => {
              this.solutionPilars = pilarResult;
              this.skuSolPilars = pilarResult.filter(
                (x) =>
                  x.solutionPilarName.toLowerCase() == 'avaya business line' ||
                  x.solutionPilarName.toLowerCase() == 'resold services'
              );
            },
            (error) => {
              this.solutionPilars = [];
              this.skuSolPilars = [];
            }
          );
          if (this.secId) {
            this.psService.getSECComponentById(this.secId).subscribe(
              (secResult) => {
                this.sec = secResult;
                const data = secResult;
                this.pricingNotes = data.pricingNotes;
                this.selectedServiceRole = data.serviceRole;
                this.serviceEstimateType =
                  data.serviceEstimate.serviceEstimateType;
                this.businessPartners = Object.assign(
                  [],
                  data.businessPartnerItems
                );
                if (data.prevailingWageStateId) {
                  this.locationService
                    .getCountiesByStateId(data.prevailingWageStateId)
                    .subscribe((res) => {
                      this.counties = res;
                    });
                }
                data.phaseItems.forEach((item: any) => {
                  if (item.phase == 0) {
                    this.addItem(0, item);
                  } else if (item.phase == 1) {
                    this.addItem(ProjectPhase.Execute, item);
                  } else if (item.phase == 2) {
                    this.addItem(ProjectPhase.Close, item);
                  }
                });
                data.materialItems.forEach((mi: any) => {
                  this.addMeterialItem(mi);
                });
                data.businessPartnerItems.forEach((bi: any) => {
                  this.addBusinessItem(bi);
                });
                data.serviceEstimateComponentTags.forEach((tag: any) => {
                  this.addTagItem(tag);
                  let tagIndex: number = this.actualTags?.findIndex(
                    (x) => x.id == tag.tagId
                  );
                  this.actualTags?.splice(tagIndex, 1);
                });
                data.travelExpenses.forEach((te: any) => {
                  this.addTravelExpense(te);
                });
                data.skuItems.forEach((si: any) => {
                  this.addSkuItem(si);
                });
                const totalsForView = this.calculateTotalsForView(data);
                this.isNotesRequired = data.priceAdjustment ? true : false;
                this.serviceEstimateForm = this.fb.group({
                  id: data.id,
                  serviceRoleId: [data.serviceRoleId, Validators.required],
                  componentOverrideRate: [data.componentOverrideRate],
                  createdByEmployeeId: [data.createdByEmployeeId],
                  phaseItems: [],
                  componentName: [data.componentName, Validators.required],
                  totalAmount: totalsForView.total,
                  totalHours: [totalsForView.totalHours],
                  businessPartnerTotal: data.businessPartnerTotal,
                  notes: [
                    data.notes,
                    data.priceAdjustment ? Validators.required : null,
                  ],
                  priceAdjustment: data.priceAdjustment
                    ? data.priceAdjustment
                    : null,
                  serviceEstimateDetailsPlanning:
                    this.serviceEstimateDetailsPlanning,
                  serviceEstimateDetailsClosing:
                    this.serviceEstimateDetailsClosing,
                  serviceEstimateDetailsExecute:
                    this.serviceEstimateDetailsExecute,
                  businessPartnerItems: this.businessPartnerItems,
                  materialItems: this.materialItems,
                  travelExpenses: this.travelExpenses,
                  serviceEstimateId: [this.seId],
                  skuItems: this.skuItems,
                  version: [data.version],
                  isAttached: [data.isAttached],
                  isApproved: [data.isApproved],
                  isReadyForApproval: [data.isReadyForApproval],
                  isRejected: [data.isRejected],
                  engagementType: [data.engagementType, Validators.required],
                  serviceEstimateComponentTags: this.tagItems,
                  grossMarginPercentage: [data.grossMarginPercentage],
                  upliftPercentage: [data.upliftPercentage],
                  hasPrevailingWage: [data.hasPrevailingWage],
                  prevailingWageStateId: [data.prevailingWageStateId],
                  prevailingWageCountyId: [data.prevailingWageCountyId],
                  prevailingWageNotes: [data.prevailingWageNotes],
                });
                this.selectableBusinessPartners =
                  this.serviceEstimateForm.controls[
                    'businessPartnerItems'
                  ].value;
                this.setEngagementType();
                this.setupServiceRolesChange();
                this.onGrossMarginPercentageChange();
                this.onTotalUpliftPercentageChange();
                this.setGrossMarginValidations();
                this.setUpliftValidations();
                this.onBusinessPartnersChange();
                this.onPrevailingWageStateChange();
                this.onHasPrevailingWageChange();
                this.serviceEstimateForm.valueChanges
                  .pipe(debounceTime(1000))
                  .subscribe((formVa) => {
                    this.serviceEstimateForm.updateValueAndValidity();
                    this.isValid = this.serviceEstimateForm.valid;
                    const priceAdjustValue =
                      this.serviceEstimateForm.controls['priceAdjustment'];
                    const notesControl =
                      this.serviceEstimateForm.controls['notes'];
                    if (priceAdjustValue.dirty) {
                      if (priceAdjustValue.value) {
                        this.isNotesRequired = true;
                        notesControl.setValidators(Validators.required);
                        notesControl.updateValueAndValidity();
                      } else {
                        this.isNotesRequired = false;
                        notesControl.clearValidators();
                        notesControl.updateValueAndValidity();
                      }
                    }
                    this.calculateTotal();
                  });
                this.calculateTotal();
                this.onOverrideRateChange();
              },
              (error) => {
                this.serviceEstimateForm = this.fb.group({
                  componentName: ['', Validators.required],
                  serviceRoleId: [null, Validators.required],
                  componentOverrideRate: [null],
                  totalAmount: null,
                  totalHours: [null],
                  createdByEmployeeId: [],
                  notes: [''],
                  priceAdjustment: null,
                  phaseItems: [],
                  serviceEstimateDetailsPlanning:
                    this.serviceEstimateDetailsPlanning,
                  serviceEstimateDetailsClosing:
                    this.serviceEstimateDetailsClosing,
                  serviceEstimateDetailsExecute:
                    this.serviceEstimateDetailsExecute,
                  businessPartnerItems: this.businessPartnerItems,
                  materialItems: this.materialItems,
                  travelExpenses: this.travelExpenses,
                  skuItems: this.skuItems,
                  serviceEstimateId: [this.seId],
                  businessPartnerTotal: [],
                  engagementType: [null, Validators.required],
                  serviceEstimateComponentTags: this.tagItems,
                  grossMarginPercentage: [null],
                  upliftPercentage: [null],
                  hasPrevailingWage: [null],
                  prevailingWageStateId: [null],
                  prevailingWageCountyId: [null],
                  prevailingWageNotes: [null],
                });
                this.setupServiceRolesChange();
                this.onGrossMarginPercentageChange();
                this.onTotalUpliftPercentageChange();
                this.onBusinessPartnersChange();
                this.onPrevailingWageStateChange();
                this.onHasPrevailingWageChange();
                this.serviceEstimateForm.valueChanges
                  .pipe(debounceTime(1000))
                  .subscribe((formVa) => {
                    this.serviceEstimateForm.updateValueAndValidity();
                    this.isValid = this.serviceEstimateForm.valid;
                    const priceAdjustValue =
                      this.serviceEstimateForm.controls['priceAdjustment'];
                    const notesControl =
                      this.serviceEstimateForm.controls['notes'];
                    if (priceAdjustValue.dirty) {
                      if (priceAdjustValue.value) {
                        this.isNotesRequired = true;
                        notesControl.setValidators(Validators.required);
                        notesControl.updateValueAndValidity();
                      } else {
                        this.isNotesRequired = false;
                        notesControl.clearValidators();
                        notesControl.updateValueAndValidity();
                      }
                    }

                    this.calculateTotal();
                  });
                  this.onOverrideRateChange();
              }
            );
          } else {
            this.selectableBusinessPartners = [];
            this.serviceEstimateForm = this.fb.group({
              componentName: ['', Validators.required],
              serviceRoleId: ['', Validators.required],
              componentOverrideRate: [null],
              totalAmount: null,
              totalHours: [null],
              notes: [''],
              priceAdjustment: null,
              createdByEmployeeId: [],
              serviceEstimateDetailsPlanning:
                this.serviceEstimateDetailsPlanning,
              serviceEstimateDetailsClosing: this.serviceEstimateDetailsClosing,
              serviceEstimateDetailsExecute: this.serviceEstimateDetailsExecute,
              businessPartnerItems: this.businessPartnerItems,
              materialItems: this.materialItems,
              travelExpenses: this.travelExpenses,
              skuItems: this.skuItems,
              serviceEstimateId: [this.seId],
              businessPartnerTotal: [],
              engagementType: [null, Validators.required],
              serviceEstimateComponentTags: this.tagItems,
              grossMarginPercentage: [null],
              upliftPercentage: [null],
              hasPrevailingWage: [null],
              prevailingWageStateId: [null],
              prevailingWageCountyId: [null],
              prevailingWageNotes: [null],
            });
            this.setupServiceRolesChange();
            this.onGrossMarginPercentageChange();
            this.onTotalUpliftPercentageChange();
            this.onBusinessPartnersChange();
            this.onPrevailingWageStateChange();
            this.onHasPrevailingWageChange();
            this.serviceEstimateForm.valueChanges
              .pipe(debounceTime(1000))
              .subscribe((formVa) => {
                this.serviceEstimateForm.updateValueAndValidity();
                this.isValid = this.serviceEstimateForm.valid;
                const priceAdjustValue =
                  this.serviceEstimateForm.controls['priceAdjustment'];
                const notesControl = this.serviceEstimateForm.controls['notes'];
                if (priceAdjustValue.dirty) {
                  if (priceAdjustValue.value) {
                    this.isNotesRequired = true;
                    notesControl.setValidators(Validators.required);
                    notesControl.updateValueAndValidity();
                  } else {
                    this.isNotesRequired = false;
                    notesControl.clearValidators();
                    notesControl.updateValueAndValidity();
                  }
                }

                this.calculateTotal();
              });
              this.onOverrideRateChange();
          }
        });
    });
  }

  setServiceRoles(res: any) {
    if (this.secId) this.serviceRoles = res;
    else if (this.serviceEstimateType == 3)
      this.serviceRoles = res.filter(
        (sr: any) => sr.softDelete == false && sr.serviceEstimateType == 3
      );
    else this.serviceRoles = res.filter((sr: any) => sr.softDelete == false);
  }

  setEngagementType() {
    let engagementControl: UntypedFormControl = this.serviceEstimateForm
      .controls['engagementType'] as UntypedFormControl;
    if (engagementControl.value == 1 && !this.serviceEstimate?.isLocked) {
      engagementControl.setValue(null);
      engagementControl.updateValueAndValidity();
    }
  }

  onOverrideRateChange() {
    this.serviceEstimateForm.controls['componentOverrideRate'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((componentOverrideRate) => {
        const rateToUse = componentOverrideRate || this.selectedServiceRole?.serviceRoleHourlyRate;
  
        // Update the overrideRate for planning phase items
        const planningItemsArray = this.serviceEstimateDetailsPlanning as UntypedFormArray;
        planningItemsArray.controls.forEach((control: any) => {
          control.get('overrideRate')?.setValue(rateToUse);
        });
  
        // Update the overrideRate for closing phase items
        const closingItemsArray = this.serviceEstimateDetailsClosing as UntypedFormArray;
        closingItemsArray.controls.forEach((control: any) => {
          control.get('overrideRate')?.setValue(rateToUse);
        });
  
        // Update the overrideRate for execute phase items
        const executeItemsArray = this.serviceEstimateDetailsExecute as UntypedFormArray;
        executeItemsArray.controls.forEach((control: any) => {
          control.get('overrideRate')?.setValue(rateToUse);
        });
  
        // Update form validity
        this.serviceEstimateForm.updateValueAndValidity();
      });
  }
  
  
  
  
  
  
  

  onBusinessPartnersChange() {
    this.serviceEstimateForm.controls['businessPartnerItems'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((businessPartnerItems) => {
        this.selectableBusinessPartners = businessPartnerItems;
        this.serviceEstimateForm.updateValueAndValidity();
        this.clearInvalidBusinessPartners();
      });
  }

  clearInvalidBusinessPartners() {
    const validBusinessPartnerIds = this.selectableBusinessPartners.map(
      (bp) => bp.businessPartnerId
    );
    this.travelExpenses.controls.forEach((expenseControl) => {
      const travelExpenseBusinessPartnerId = expenseControl.get(
        'travelExpenseBusinessPartnerId'
      )?.value;
      if (!validBusinessPartnerIds.includes(travelExpenseBusinessPartnerId)) {
        expenseControl.get('travelExpenseBusinessPartnerId')?.setValue(null);
      }
    });
    this.materialItems.controls.forEach((materialExpenseControl) => {
      const materialItemBusinessPartnerId = materialExpenseControl.get(
        'materialItemBusinessPartnerId'
      )?.value;
      if (!validBusinessPartnerIds.includes(materialItemBusinessPartnerId)) {
        materialExpenseControl.get('materialItemBusinessPartnerId')?.setValue(null);
      }
    });
  }

  onTravelExpenseTypeChanged(index: number) {
    const expenseControl = this.travelExpenses.at(index) as UntypedFormArray;
    const travelExpenseTypeControl = expenseControl.get('travelExpenseType');
    const travelExpenseBusinessPartnerIdControl = expenseControl.get(
      'travelExpenseBusinessPartnerId'
    );
    travelExpenseTypeControl?.valueChanges.subscribe((value) => {
      if (value === 'Meridian') {
        travelExpenseBusinessPartnerIdControl?.setValidators(null);
        travelExpenseBusinessPartnerIdControl?.setValue(null); // Clear the value if it's not needed
      } else {
        travelExpenseBusinessPartnerIdControl?.setValidators([
          Validators.required,
        ]);
      }
      travelExpenseBusinessPartnerIdControl?.updateValueAndValidity();
    });
    const currentValue = travelExpenseTypeControl?.value;
    if (currentValue === 'Meridian') {
      travelExpenseBusinessPartnerIdControl?.setValidators(null);
      travelExpenseBusinessPartnerIdControl?.setValue(null);
    } else {
      travelExpenseBusinessPartnerIdControl?.setValidators([
        Validators.required,
      ]);
    }
    travelExpenseBusinessPartnerIdControl?.updateValueAndValidity();
  }

  onHasPrevailingWageChange() {
    this.serviceEstimateForm.controls[
      'hasPrevailingWage'
    ].valueChanges.subscribe((hasPrevailingWage) => {
      if (hasPrevailingWage) {
        this.serviceEstimateForm.controls[
          'prevailingWageStateId'
        ].setValidators([Validators.required]);
        this.serviceEstimateForm.controls[
          'prevailingWageCountyId'
        ].setValidators([Validators.required]);
      } else {
        this.serviceEstimateForm.controls[
          'prevailingWageStateId'
        ].clearValidators();
        this.serviceEstimateForm.controls[
          'prevailingWageCountyId'
        ].clearValidators();
        this.serviceEstimateForm.controls[
          'prevailingWageNotes'
        ].clearValidators();
        this.serviceEstimateForm.controls['prevailingWageStateId'].setValue(
          null
        );
        this.serviceEstimateForm.controls['prevailingWageCountyId'].setValue(
          null
        );
        this.serviceEstimateForm.controls['prevailingWageNotes'].setValue(null);
      }
      this.serviceEstimateForm.controls[
        'prevailingWageStateId'
      ].updateValueAndValidity();
      this.serviceEstimateForm.controls[
        'prevailingWageCountyId'
      ].updateValueAndValidity();
      this.serviceEstimateForm.controls[
        'prevailingWageNotes'
      ].updateValueAndValidity();
    });
  }

  notNullOrEmpty(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value.trim() === '') {
      return { notNullOrEmpty: true };
    }
    return null;
  }

  onPrevailingWageStateChange() {
    this.serviceEstimateForm.controls['prevailingWageStateId'].valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((prevailingWageStateId) => {
        this.locationService
          .getCountiesByStateId(prevailingWageStateId)
          .subscribe((res) => {
            this.counties = res;
          });
      });
  }

  onGrossMarginPercentageChange() {
    this.serviceEstimateForm.controls['grossMarginPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((grossMarginValue) => {
        this.calcBPControls('grossMarginPercentage');
      });
  }

  onTotalUpliftPercentageChange() {
    this.serviceEstimateForm.controls['upliftPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((upliftValue) => {
        this.calcBPControls('totalUpliftPercentage');
      });
  }

  isNotValidBpGrossMarginPerct(index: number) {
    let bpValue = this.businessPartnerItems.controls[index].value;
    return bpValue.bpGrossMarginPercentage?.toFixed(2) <
      (this.setBPGrossMargin() ?? 0) && !this.serviceEstimate?.isLocked
      ? true
      : false;
  }

  isNotValidTotalGrossMarginPerct() {
    let totalGrossMarginValue =
      this.serviceEstimateForm.controls['grossMarginPercentage'].value;
    return totalGrossMarginValue?.toFixed(2) < (this.setBPGrossMargin() ?? 0) &&
      !this.serviceEstimate?.isLocked
      ? true
      : false;
  }

  isNotValidUpliftPerct(index: number) {
    let bpValue = this.businessPartnerItems.controls[index].value;
    return bpValue.upliftPercentage?.toFixed(2) <
      (this.setBPUpliftMargin() ?? 0) && !this.serviceEstimate?.isLocked
      ? true
      : false;
  }

  isNotValidTotalUpliftPerct() {
    let totalUpliftValue =
      this.serviceEstimateForm.controls['upliftPercentage'].value;
    return totalUpliftValue?.toFixed(2) < (this.setBPUpliftMargin() ?? 0) &&
      !this.serviceEstimate?.isLocked
      ? true
      : false;
  }

  setGrossMarginValidations(isManualyAdded?: boolean) {
    const grossMarginPercentageControl =
      this.serviceEstimateForm?.controls['grossMarginPercentage'];
    if (grossMarginPercentageControl && !this.serviceEstimate?.isLocked) {
      if (this.businessPartnerItems.length > 0) {
        grossMarginPercentageControl.setValidators([
          Validators.required,
          Validators.max(99.99),
          Validators.min(0),
        ]); // Validators.pattern('\\-?\\d*\\.?\\d{1,4}')]
        !grossMarginPercentageControl.value &&
        grossMarginPercentageControl.value != 0
          ? grossMarginPercentageControl.setValue(
              this.secId
                ? isManualyAdded
                  ? grossMarginPercentageControl.value
                    ? grossMarginPercentageControl.value
                    : this.setBPGrossMargin()
                  : null
                : this.setBPGrossMargin(),
              { emitEvent: false }
            )
          : '';
      } else {
        grossMarginPercentageControl.clearValidators();
        grossMarginPercentageControl.setValue(null, { emitEvent: false });
      }
      grossMarginPercentageControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  setUpliftValidations(isManualyAdded?: boolean) {
    const totalUpliftPercentageControl =
      this.serviceEstimateForm?.controls['upliftPercentage'];
    if (totalUpliftPercentageControl && !this.serviceEstimate?.isLocked) {
      if (this.businessPartnerItems.length > 0) {
        totalUpliftPercentageControl.setValidators([
          Validators.required,
          Validators.min(0),
        ]); // Validators.pattern('\\-?\\d*\\.?\\d{1,4}')]
        !totalUpliftPercentageControl.value &&
        totalUpliftPercentageControl.value != 0
          ? totalUpliftPercentageControl.setValue(
              this.secId
                ? isManualyAdded
                  ? totalUpliftPercentageControl.value
                    ? totalUpliftPercentageControl.value
                    : this.setBPUpliftMargin()
                  : null
                : this.setBPUpliftMargin(),
              { emitEvent: false }
            )
          : '';
      } else {
        totalUpliftPercentageControl.clearValidators();
        totalUpliftPercentageControl.setValue(null, { emitEvent: false });
      }
      totalUpliftPercentageControl.updateValueAndValidity({ emitEvent: false });
    }
  }

  setupServiceRolesChange() {
    this.serviceEstimateForm.controls['serviceRoleId'].valueChanges.subscribe(
      (value) => {
        this.onServiceRoleChange(value);
        var overrideRate = this.serviceEstimateForm.controls['componentOverrideRate'].value;
        if (this.serviceEstimateDetailsPlanning) {
          this.serviceEstimateDetailsPlanning.controls.forEach((c: any) => {
            c.controls['serviceRoleId'].setValue(value);
            const selectedRole = this.serviceRoles.filter(
              (sr) => sr.serviceRoleId === value
            )[0];
            c.controls['overrideRate'].setValue(
              overrideRate ? overrideRate : selectedRole.serviceRoleHourlyRate
            );
            c.controls['billedRate'].setValue(
              selectedRole.serviceRoleHourlyRate
            );
            c.controls['serviceRatePerQuanity'].setValue(
              selectedRole['serviceRoleHourlyRate']
            );
            this.performEstimatiedPriceCalulation(c);
          });
          if (this.serviceEstimateDetailsPlanning.length == 0) {
            const item = { activity: 'Status Meetings' };
            this.addItem(ProjectPhase.Plan, item);
            this.updatePhaseIndex(ProjectPhase.Plan);
          }
        }
        if (this.serviceEstimateDetailsClosing) {
          this.serviceEstimateDetailsClosing.controls.forEach((c: any) => {
            c.controls['serviceRoleId'].setValue(value);
            const selectedRole = this.serviceRoles.filter(
              (sr) => sr.serviceRoleId === value
            )[0];
            c.controls['overrideRate'].setValue(
              overrideRate ? overrideRate : selectedRole.serviceRoleHourlyRate
            );
            c.controls['billedRate'].setValue(
              selectedRole.serviceRoleHourlyRate
            );
            c.controls['serviceRatePerQuanity'].setValue(
              selectedRole['serviceRoleHourlyRate']
            );
            this.performEstimatiedPriceCalulation(c);
          });
        }
        if (this.serviceEstimateDetailsExecute) {
          this.serviceEstimateDetailsExecute.controls.forEach((c: any) => {
            c.controls['serviceRoleId'].setValue(value);
            const selectedRole = this.serviceRoles.filter(
              (sr) => sr.serviceRoleId === value
            )[0];
            c.controls['overrideRate'].setValue(
              overrideRate ? overrideRate : selectedRole.serviceRoleHourlyRate
            );
            c.controls['billedRate'].setValue(
              selectedRole.serviceRoleHourlyRate
            );
            c.controls['serviceRatePerQuanity'].setValue(
              selectedRole['serviceRoleHourlyRate']
            );
            this.performEstimatiedPriceCalulation(c);
          });
        }
        this.serviceEstimateForm.updateValueAndValidity();
      }
    );
  }
  addBusinessItem(serviceDetailItem?: any) {
    let isManualyAdded: boolean = !serviceDetailItem;
    if (!serviceDetailItem) {
      serviceDetailItem = {};
      serviceDetailItem.phase = 3;
    }
    const groupItem = this.buildBPGroup(serviceDetailItem);
    if (!this.businessPartnerItems) {
      this.businessPartnerItems = this.fb.array([groupItem]);
      this.setGrossMarginValidations(isManualyAdded);
      this.setUpliftValidations(isManualyAdded);
    } else {
      this.businessPartnerItems.push(groupItem);
      this.setGrossMarginValidations(isManualyAdded);
      this.setUpliftValidations(isManualyAdded);
    }
  }
  addSkuItem(sku?: any) {
    if (!sku) {
      sku = {};
    }
    const skuItem = this.buildSKUGroup(sku);
    if (!this.skuItems) {
      this.skuItems = this.fb.array([skuItem]);
    } else {
      this.skuItems.push(skuItem);
    }
  }
  addTravelExpense(travelExpense?: any) {
    if (!travelExpense) {
      travelExpense = {};
    }
    const travelExpenseItem = this.buildTEGroup(travelExpense);
    if (!this.travelExpenses) {
      this.travelExpenses = this.fb.array([travelExpenseItem]);
    } else {
      this.travelExpenses.push(travelExpenseItem);
    }
  }
  addItem(
    phase: ProjectPhase | number,
    serviceDetailItem?: any,
    isNewRecord?: boolean
  ) {
    var overrideRate = null;
    if(this.serviceEstimateForm?.controls['componentOverrideRate'].value) {
      overrideRate = this.serviceEstimateForm?.controls['componentOverrideRate'].value;
    }
    if (!serviceDetailItem) {
      serviceDetailItem = new ServiceEstimateDetail();
    }
    serviceDetailItem.phase = phase;
    if (!this.secId) {
      serviceDetailItem.serviceRoleId =
        this.serviceEstimateForm.controls['serviceRoleId'].value;
      const clientRates: Array<any> =
        this.serviceEstimate.serviceProject.client.clientRates;
      const clientRate = clientRates.filter(
        (cr) => cr.serviceRoleId === serviceDetailItem.serviceRoleId
      )[0];
      if (clientRate) {
        serviceDetailItem.overrideRate = clientRate.clientHourlyRate;
      }
      if(this.serviceEstimateForm.controls['componentOverrideRate'].value) {
        serviceDetailItem.overrideRate = this.serviceEstimateForm.controls['componentOverrideRate'].value;
      }
    } else {
      if (this.serviceEstimateForm) {
        if (this.serviceEstimateForm.controls['serviceRoleId'].value) {
          serviceDetailItem.serviceRoleId =
            this.serviceEstimateForm.controls['serviceRoleId'].value;
          const clientRates: Array<any> =
            this.sec.serviceEstimate.serviceProject.client.clientRates;
          const clientRate = clientRates.filter(
            (cr) => cr.serviceRoleId === serviceDetailItem.serviceRoleId
          )[0];
          if (clientRate) {
            serviceDetailItem.overrideRate = clientRate.clientHourlyRate;
          }
          if(this.serviceEstimateForm.controls['componentOverrideRate'].value) {
            serviceDetailItem.overrideRate = this.serviceEstimateForm.controls['componentOverrideRate'].value;
          }
        } else {
          serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
        }
      } else {
        serviceDetailItem.serviceRoleId = serviceDetailItem.serviceRoleId;
      }
    }
    const groupItem = this.buildGroup(serviceDetailItem, isNewRecord);
    switch (serviceDetailItem.phase) {
      case ProjectPhase.Plan:
        if (!this.serviceEstimateDetailsPlanning) {
          this.serviceEstimateDetailsPlanning = this.fb.array([groupItem]);
        } else {
          this.serviceEstimateDetailsPlanning.push(groupItem);
        }
        break;
      case ProjectPhase.Execute:
        if (!this.serviceEstimateDetailsExecute) {
          this.serviceEstimateDetailsExecute = this.fb.array([groupItem]);
        } else {
          this.serviceEstimateDetailsExecute.push(groupItem);
        }
        break;
      case ProjectPhase.Close:
        if (!this.serviceEstimateDetailsClosing) {
          this.serviceEstimateDetailsClosing = this.fb.array([groupItem]);
        } else {
          this.serviceEstimateDetailsClosing.push(groupItem);
        }
        break;
    }
  }
  addMeterialItem(mi?: any) {
    let item: UntypedFormGroup;
    if (mi) {
      item = this.fb.group({
        id: [mi.id],
        name: [mi.name, Validators.required],
        cost: [mi.cost, Validators.required],
        sell: [mi.sell, Validators.required],
        materialItemType: [mi.materialItemType, Validators.required],
        materialItemBusinessPartnerId: [
          mi.materialItemBusinessPartnerId,
          mi.materialItemType == 'Meridian' ? null : Validators.required,
        ],
      });
    } else {
      item = this.fb.group({
        name: [null, Validators.required],
        cost: [null, Validators.required],
        sell: [null, Validators.required],
        materialItemType: [null, Validators.required],
        materialItemBusinessPartnerId: [null],
      });
    }
    item.controls['materialItemType'].valueChanges.subscribe((s) => {
      if (s == 'Business Partner') {
        item.controls['materialItemBusinessPartnerId'].setValidators(
          Validators.required
        );
        item.controls['materialItemBusinessPartnerId'].updateValueAndValidity();
      } else {
        item.controls['materialItemBusinessPartnerId'].clearValidators();
        item.controls['materialItemBusinessPartnerId'].updateValueAndValidity();
      }
    });
    this.materialItems.push(item);
  }
  removeMaterialItem(index: number) {
    const id = this.materialItems.at(index).value['id'];
    if (id) {
      this.materialService.deleteMaterialItems(id).subscribe((res) => {
        this.materialItems.removeAt(index);
      });
    } else {
      this.materialItems.removeAt(index);
    }
  }
  addTagItem(tagItem: any, isNewTag: boolean = false) {
    let item: UntypedFormGroup;
    if (!isNewTag) {
      item = this.fb.group({
        id: [tagItem.id],
        tagId: [tagItem.tagId],
        serviceEstimateComponentId: [this.secId],
        CreatedByEmployeeId: [tagItem.createdByEmployeeId],
        CreatedDate: [tagItem.createdDate],
      });
    } else {
      item = this.fb.group({
        tagId: [tagItem.id],
        serviceEstimateComponentId: [this.secId],
      });
    }
    this.tagItems.push(item);
  }
  removeTagItem(index: number, event: Event) {
    this.isRemoveBtnDisabled = true;
    let tag = this.tagItems.at(index).value;
    event.stopPropagation();
    if (tag.id) {
      this.tagsService.removeSEComponentTag(tag.id).subscribe((res) => {
        this.tagItems.removeAt(index);
        this.isRemoveBtnDisabled = false;
      });
    } else {
      this.tagItems.removeAt(index);
      this.isRemoveBtnDisabled = false;
    }
    let tagToInsertIndex: number = this.tags.findIndex(
      (t) => t.id == tag.tagId
    );
    this.actualTags.push(this.tags[tagToInsertIndex]);
    this.tagComponent.setTagControlValue();
  }
  buildGroup(serviceEstimateDetail?: any, isNewRecord?: boolean) {
    const _sr = this.serviceRoles.filter(
      (sr) => sr.serviceRoleId == serviceEstimateDetail.serviceRoleId
    )[0];
    if (serviceEstimateDetail.id || isNewRecord) {
      this.totalHours += serviceEstimateDetail.quantity;
      let group: UntypedFormGroup;
      const phase1 = serviceEstimateDetail.phase
        ? serviceEstimateDetail.phase
        : null;
      group = this.fb.group({
        id: serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        activity: [
          serviceEstimateDetail.activity
            ? serviceEstimateDetail.activity
            : 'Default',
          Validators.required,
        ],
        quantity: [
          serviceEstimateDetail.quantity ? serviceEstimateDetail.quantity : 0,
          Validators.compose([Validators.required, stepValidator()]),
        ], // , Validators.pattern(/^[0-9]+$/)
        estimatiedPrice: serviceEstimateDetail.estimatiedPrice
          ? serviceEstimateDetail.estimatiedPrice
          : 0,
        billedRate: serviceEstimateDetail.billedRate
          ? serviceEstimateDetail.billedRate
          : 0,
        serviceRoleId: serviceEstimateDetail.serviceRoleId
          ? serviceEstimateDetail.serviceRoleId
          : null,
        serviceRatePerQuanity: serviceEstimateDetail.serviceRatePerQuanity
          ? serviceEstimateDetail.serviceRatePerQuanity
          : 0,
        overrideRate: serviceEstimateDetail.overrideRate
          ? serviceEstimateDetail.overrideRate
          : 0,
        expenseEstimate: serviceEstimateDetail.estimatiedPrice
          ? serviceEstimateDetail.estimatiedPrice
          : 0,
        multiplier: [
          serviceEstimateDetail.multiplier
            ? serviceEstimateDetail.multiplier
            : 1,
          [nonZeroValidator],
        ],
        phase: serviceEstimateDetail.phase,
        activityNotes: serviceEstimateDetail.activityNotes,
        isInstallation: serviceEstimateDetail.isInstallation,
        isSpecialApplicationAndPremierService:
          serviceEstimateDetail.isSpecialApplicationAndPremierService,
        isPrevailingWage: serviceEstimateDetail.isPrevailingWage,
        phaseItemOrder: serviceEstimateDetail.phaseItemOrder,
      });
      this.setupCalculationOnGroup(group);
      return group;
    } else {
      const group = this.fb.group({
        activity: [
          serviceEstimateDetail && serviceEstimateDetail.activity
            ? serviceEstimateDetail.activity
            : 'Default',
          Validators.required,
        ],
        id: serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        quantity: [
          0,
          Validators.compose([Validators.required, stepValidator()]),
        ],
        estimatiedPrice: 0,
        billedRate: _sr ? _sr.serviceRoleHourlyRate : 0,
        serviceRoleId: serviceEstimateDetail.serviceRoleId
          ? serviceEstimateDetail.serviceRoleId
          : null,
        serviceRatePerQuanity: _sr ? _sr.serviceRoleHourlyRate : 0,
        overrideRate: serviceEstimateDetail.overrideRate
          ? serviceEstimateDetail.overrideRate
          : _sr
          ? _sr.serviceRoleHourlyRate
          : 0,
        expenseEstimate: 0,
        multiplier: [1, [nonZeroValidator]],
        phase: serviceEstimateDetail.phase,
        activityNotes: '',
        isInstallation: false,
        isPrevailingWage: false,
        isSpecialApplicationAndPremierService: false,
        phaseItemOrder: serviceEstimateDetail.phaseItemOrder,
      });
      this.setupCalculationOnGroup(group);
      return group;
    }
  }
  buildBPGroup(serviceEstimateDetail?: any) {
    let group: UntypedFormGroup;
    if (serviceEstimateDetail.id) {
      group = this.fb.group({
        id: serviceEstimateDetail.id ? serviceEstimateDetail.id : null,
        businessPartnerId: serviceEstimateDetail.businessPartnerId
          ? serviceEstimateDetail.businessPartnerId
          : '',
        resourceType: 1,
        activity: [
          serviceEstimateDetail.activity ? serviceEstimateDetail.activity : '',
          Validators.required,
        ],
        bpName: [
          serviceEstimateDetail.businessPartner
            ? serviceEstimateDetail.businessPartner.businessPartnerLegalName
            : '',
          Validators.required,
        ],
        estimatiedPrice: [
          serviceEstimateDetail.estimatiedPrice
            ? serviceEstimateDetail.estimatiedPrice
            : 0,
          Validators.required,
        ],
        rate: serviceEstimateDetail.actualRate
          ? serviceEstimateDetail.actualRate
          : 0,
        serviceRatePerQuanity: serviceEstimateDetail.actualRate
          ? serviceEstimateDetail.actualRate
          : 0,
        overrideRate: serviceEstimateDetail.actualRate
          ? serviceEstimateDetail.actualRate
          : 0,
        expenseEstimate: serviceEstimateDetail.estimatiedPrice
          ? serviceEstimateDetail.estimatiedPrice
          : 0,
        isFixedCost: this.isFixedFeeSE() ? 1 : 0,
        cost: [
          serviceEstimateDetail.cost ? serviceEstimateDetail.cost : 0,
          Validators.required,
        ],
        sell: [serviceEstimateDetail.sell ? serviceEstimateDetail.sell : 0],
        upliftPercentage: serviceEstimateDetail.upliftPercentage
          ? serviceEstimateDetail.upliftPercentage
          : [
              serviceEstimateDetail.cost && serviceEstimateDetail.sell
                ? ((serviceEstimateDetail.sell - serviceEstimateDetail.cost) /
                    serviceEstimateDetail.cost) *
                  100
                : null,
            ],
        estimatedHours: [
          this.isFixedFeeSE()
            ? { value: null, disabled: true }
            : serviceEstimateDetail.estimatedHours
            ? serviceEstimateDetail.estimatedHours
            : null,
          Validators.required,
        ],
        solutionPilarId: [
          serviceEstimateDetail.solutionPilarId
            ? serviceEstimateDetail.solutionPilarId
            : 0,
          Validators.required,
        ],
        bpGrossMarginPercentage: [
          serviceEstimateDetail.bpGrossMarginPercentage
            ? serviceEstimateDetail.bpGrossMarginPercentage
            : serviceEstimateDetail.cost && serviceEstimateDetail.sell
            ? (1 - serviceEstimateDetail.cost / serviceEstimateDetail.sell) *
              100
            : null,
          Validators.compose([
            Validators.required,
            Validators.max(99.99),
            Validators.min(0),
          ]),
        ],
      });
    } else {
      group = this.fb.group({
        businessPartnerId: '',
        solutionPilarId: [null, Validators.required],
        activity: [
          serviceEstimateDetail.activity ? serviceEstimateDetail.activity : '',
          Validators.required,
        ],
        bpName: ['', Validators.required],
        estimatiedPrice: [0, Validators.required],
        rate: 0,
        cost: [0, Validators.required],
        sell: [0],
        upliftPercentage: [this.setBPUpliftMargin()],
        expenseEstimate: 0,
        isFixedCost: this.isFixedFeeSE() ? 1 : 0,
        estimatedHours: [
          this.isFixedFeeSE() ? { value: null, disabled: true } : null,
          Validators.required,
        ],
        bpGrossMarginPercentage: [
          this.setBPGrossMargin(),
          Validators.compose([
            Validators.required,
            Validators.max(99.99),
            Validators.min(0),
          ]),
        ],
      });
    }
    this.businessPartnerCalulcationOnGroup(group);
    return group;
  }
  buildTEGroup(travelExpense?: any) {
    let group: UntypedFormGroup;
    if (travelExpense.id) {
      group = this.fb.group({
        id: [travelExpense.id ? travelExpense.id : null],
        airfare: [travelExpense.airfare ? travelExpense.airfare : 0],
        auto: [travelExpense.auto ? travelExpense.auto : 0],
        hotel: [travelExpense.hotel ? travelExpense.hotel : 0],
        gas: [travelExpense.gas ? travelExpense.gas : 0],
        meals: [travelExpense.meals ? travelExpense.meals : 0],
        other: [travelExpense.other ? travelExpense.other : 0],
        isLocalClient: [
          travelExpense.isLocalClient ? travelExpense.isLocalClient : false,
        ],
        travelExpenseType: [
          travelExpense.travelExpenseType
            ? travelExpense.travelExpenseType
            : null,
          Validators.required,
        ],
        travelExpenseBusinessPartnerId: [
          travelExpense.travelExpenseBusinessPartnerId
            ? travelExpense.travelExpenseBusinessPartnerId
            : null,
          this.getInitialBusinessPartnerValidator(
            travelExpense.travelExpenseType
          ),
        ],
        travelHours: [
          travelExpense.travelHours ? travelExpense.travelHours : 0,
        ],
        perHourCharge: [
          travelExpense.perHourCharge ? travelExpense.perHourCharge : 100,
        ],
        airfareNotes: [travelExpense.airfareNotes],
        autoNotes: [travelExpense.autoNotes],
        hotelNotes: [travelExpense.hotelNotes],
        gasNotes: [travelExpense.gasNotes],
        mealNotes: [travelExpense.mealNotes],
        otherNotes: [travelExpense.otherNotes],
      });
    } else {
      group = this.fb.group({
        airfare: [0],
        auto: [0],
        hotel: [0],
        gas: [0],
        meals: [0],
        other: [0],
        isLocalClient: [false],
        travelExpenseType: [null, Validators.required],
        travelExpenseBusinessPartnerId: [null, Validators.required],
        travelHours: [0],
        perHourCharge: [100],
        airfareNotes: [null],
        autoNotes: [null],
        hotelNotes: [null],
        gasNotes: [null],
        mealNotes: [null],
        otherNotes: [null],
      });
    }
    return group;
  }

  getInitialBusinessPartnerValidator(
    travelExpenseType: string | null
  ): Validators | null {
    return travelExpenseType === 'Meridian' ? null : Validators.required;
  }
  buildSKUGroup(sku?: any) {
    let group: UntypedFormGroup;
    if (sku.id) {
      group = this.fb.group({
        id: [sku.id ? sku.id : null],
        activity: [sku.activity ? sku.activity : '', Validators.required],
        solutionPilarId: [
          sku.solutionPilarId ? sku.solutionPilarId : 0,
          Validators.required,
        ],
        cost: [sku.cost ? sku.cost : 0, Validators.required],
      });
    } else {
      group = this.fb.group({
        activity: ['', Validators.required],
        solutionPilarId: [null, Validators.required],
        cost: [0, Validators.required],
      });
    }
    this.skuCalcOnGroup(group);
    return group;
  }
  businessPartnerCalulcationOnGroup(group: UntypedFormGroup) {
    group.controls['cost'].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((res) => {
        this.costEval(group);
        this.calcBPControls('cost', group);
      });
    group.controls['solutionPilarId'].valueChanges.subscribe((res) => {
      this.costEval(group);
    });
    group.controls['estimatedHours'].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((res) => {
        this.costEval(group);
      });
    group.controls['sell'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((res) => {
        this.calcBPControls('sell', group);
      });
    group.controls['upliftPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((res) => {
        this.calcBPControls('upliftPercentage', group);
      });
    group.controls['bpGrossMarginPercentage'].valueChanges
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe((res) => {
        this.calcBPControls('bpGrossMarginPercentage', group);
      });
  }
  skuCalcOnGroup(group: UntypedFormGroup) {
    group.controls['solutionPilarId'].valueChanges.subscribe((res) => {
      this.skuCostEval(group);
    });
    group.controls['cost'].valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((res) => {
        this.skuCostEval(group);
      });
  }
  calcBPControls(fieldName: string, formValue?: UntypedFormGroup) {
    if (fieldName == 'cost' || fieldName == 'bpGrossMarginPercentage') {
      this.calcSellUplift(formValue, fieldName != 'cost');
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    } else if (fieldName == 'sell') {
      this.calcBpGmpUplift(formValue);
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    } else if (fieldName == 'grossMarginPercentage') {
      this.calcGrossMarginPerct();
      this.calcAllSellUplift();
      this.setTotalUpliftPercentage();
    } else if (fieldName == 'upliftPercentage') {
      this.calcBpGmpSell(formValue);
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    } else if (fieldName == 'totalUpliftPercentage') {
      this.calcUpliftPerct();
      this.calcAllSellGMP();
      this.setTotalGrossMarginPercentage();
    }
  }
  calcAllSellUplift() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get(
      'businessPartnerItems'
    ) as UntypedFormArray;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      this.calcSellUplift(bpItem, true);
    });
  }
  calcAllSellGMP() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get(
      'businessPartnerItems'
    ) as UntypedFormArray;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      this.calcBpGmpSell(bpItem);
    });
  }
  calcSellUplift(group?: UntypedFormGroup, calcUplift?: boolean) {
    let bpGrossMarginPercentage: any =
      group?.controls['bpGrossMarginPercentage'].value;
    let uplift;
    if (bpGrossMarginPercentage < 100 && bpGrossMarginPercentage >= 0) {
      const cost = group?.controls.cost.value;
      const sellValue = (cost * 100) / (100 - bpGrossMarginPercentage);
      uplift = cost && sellValue ? ((sellValue - cost) / cost) * 100 : null;
      group?.controls['sell'].setValue(sellValue, { emitEvent: false });
    } else {
      group?.controls['sell'].setValue(0, { emitEvent: false });
      uplift = null;
    }
    if (
      !this.isUpliftDefaulted &&
      group?.controls['bpGrossMarginPercentage'].value == null &&
      !calcUplift
    ) {
      this.calcBpGmpSell(group);
    } else if (
      !this.isUpliftDefaulted ||
      calcUplift ||
      group?.controls['upliftPercentage'].value == null
    ) {
      group?.controls['upliftPercentage'].setValue(uplift, {
        emitEvent: false,
      });
    } else {
      this.calcBpGmpSell(group);
    }
  }
  calcBpGmpUplift(group?: UntypedFormGroup) {
    let sellValue: any = group?.controls['sell'].value;
    let costValue: any = group?.controls['cost'].value;
    const bpGrossMarginPerct =
      costValue && sellValue ? (1 - costValue / sellValue) * 100 : null;
    const uplift =
      costValue && sellValue
        ? ((sellValue - costValue) / costValue) * 100
        : null;
    group?.controls['bpGrossMarginPercentage'].setValue(bpGrossMarginPerct, {
      emitEvent: false,
    });
    group?.controls['upliftPercentage'].setValue(uplift, { emitEvent: false });
  }
  calcBpGmpSell(group?: UntypedFormGroup) {
    let uplift: any = group?.controls['upliftPercentage'].value;
    let costValue: any = group?.controls['cost'].value;
    const sellValue = costValue * ((uplift + 100) / 100);
    const bpGrossMarginPerct =
      costValue && sellValue ? (1 - costValue / sellValue) * 100 : null;
    group?.controls['bpGrossMarginPercentage'].setValue(bpGrossMarginPerct, {
      emitEvent: false,
    });
    group?.controls['sell'].setValue(sellValue, { emitEvent: false });
  }
  calcGrossMarginPerct() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get(
      'businessPartnerItems'
    ) as UntypedFormArray;
    let grossMarginPercentageControl: UntypedFormControl = this
      .serviceEstimateForm.controls[
      'grossMarginPercentage'
    ] as UntypedFormControl;
    if (
      grossMarginPercentageControl.value != null ||
      grossMarginPercentageControl.dirty
    ) {
      businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
        bpItem.controls['bpGrossMarginPercentage'].setValue(
          grossMarginPercentageControl.value,
          { emitEvent: false }
        );
      });
    }
  }
  calcUpliftPerct() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get(
      'businessPartnerItems'
    ) as UntypedFormArray;
    let upliftPercentageControl: UntypedFormControl = this.serviceEstimateForm
      .controls['upliftPercentage'] as UntypedFormControl;
    if (
      upliftPercentageControl.value != null ||
      upliftPercentageControl.dirty
    ) {
      businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
        bpItem.controls['upliftPercentage'].setValue(
          upliftPercentageControl.value,
          { emitEvent: false }
        );
      });
    }
  }
  getMarginOptions() {
    this.marginAdjustmentsService
      .getMarginOptions()
      .subscribe((res: MarginOption | null) => {
        this.marginOption = res;
        this.isUpliftDefaulted =
          this.marginOption.marginOptionType == MarginOptionType.Uplift;
      });
  }
  setBPGrossMargin() {
    return this.marginOption.marginOptionType == MarginOptionType.GrossMargin
      ? Number.parseFloat(this.marginOption.grossMarginPercentage)
      : null;
  }
  setBPUpliftMargin() {
    return this.isUpliftDefaulted
      ? Number.parseFloat(this.marginOption.upliftPercentage)
      : null;
  }
  setTotalGrossMarginPercentage() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get(
      'businessPartnerItems'
    ) as UntypedFormArray;
    let costVal = 0;
    let sellVal = 0;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      costVal += bpItem.controls['cost'].value
        ? bpItem.controls['cost'].value
        : 0;
      sellVal += bpItem.controls['sell'].value
        ? bpItem.controls['sell'].value
        : 0;
    });
    const totalGMP = (1 - costVal / sellVal) * 100;
    this.serviceEstimateForm.controls['grossMarginPercentage'].setValue(
      totalGMP,
      { emitEvent: false }
    );
  }
  setTotalUpliftPercentage() {
    let businessPartnerItems: any = this.serviceEstimateForm?.get(
      'businessPartnerItems'
    ) as UntypedFormArray;
    let costVal = 0;
    let sellVal = 0;
    businessPartnerItems.controls.forEach((bpItem: UntypedFormGroup) => {
      costVal += bpItem.controls['cost'].value
        ? bpItem.controls['cost'].value
        : 0;
      sellVal += bpItem.controls['sell'].value
        ? bpItem.controls['sell'].value
        : 0;
    });
    const totalUplift =
      costVal && sellVal ? ((sellVal - costVal) / costVal) * 100 : null;
    this.serviceEstimateForm.controls['upliftPercentage'].setValue(
      totalUplift,
      { emitEvent: false }
    );
  }
  costEval(group: UntypedFormGroup) {
    const solPillarVal = group.controls['solutionPilarId'].value;
    if (solPillarVal) {
      let maxCharge = 0;
      group.controls['cost'].clearValidators();
      const solutionPillarRates = this.solutionPilars.find(
        (x) => x.solutionPilarId == solPillarVal
      )?.solutionPilarRates;
      solutionPillarRates?.forEach((rate) => {
        if (maxCharge <= rate.maxCharge) {
          maxCharge = rate.maxCharge;
        }
      });
      const hrs = group.controls['estimatedHours'].value;
      const maxVal = this.isFixedFeeSE()
        ? maxCharge
        : hrs
        ? maxCharge / hrs
        : maxCharge;
      group.controls['cost'].setValidators(Validators.max(maxVal));
      group.controls['cost'].updateValueAndValidity({ emitEvent: false });
    }
  }
  skuCostEval(group: UntypedFormGroup) {
    const solPillarVal = group.controls['solutionPilarId'].value;
    if (solPillarVal) {
      let maxCharge = 0;
      group.controls['cost'].clearValidators();
      const solutionPillarRates = this.solutionPilars.find(
        (x) => x.solutionPilarId == solPillarVal
      )?.solutionPilarRates;
      solutionPillarRates?.forEach((rate) => {
        if (maxCharge <= rate.maxCharge) {
          maxCharge = rate.maxCharge;
        }
      });
      group.controls['cost'].setValidators(Validators.max(maxCharge));
      group.controls['cost'].updateValueAndValidity({ emitEvent: false });
    }
  }

  setupCalculationOnGroup(group: UntypedFormGroup) {
    group.controls['serviceRoleId'].valueChanges
      .pipe(debounceTime(500))
      .subscribe((res) => {
        const selectedRole = this.serviceRoles.filter(
          (sr) => sr.serviceRoleId === res
        )[0];
        group.controls['billedRate'].setValue(
          selectedRole.serviceRoleHourlyRate
        );
        group.controls['serviceRatePerQuanity'].setValue(
          selectedRole['serviceRoleHourlyRate']
        );

        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['quantity'].valueChanges
      .pipe(debounceTime(500))
      .subscribe((res) => {
        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['multiplier'].valueChanges
      .pipe(debounceTime(500))
      .subscribe((res) => {
        this.performEstimatiedPriceCalulation(group);
      });
    group.controls['overrideRate'].valueChanges
      .pipe(debounceTime(500))
      .subscribe((res) => {
        this.performEstimatiedPriceCalulation(group);
      });
  }
  performEstimatiedPriceCalulation(formGroup: UntypedFormGroup) {
    const value = formGroup.controls['overrideRate'].value;
    if (formGroup.controls['overrideRate']) {
      if (formGroup.controls['overrideRate'].value) {
        if (formGroup.controls['overrideRate'].value !== 0) {
          if (formGroup.controls['overrideRate'].value !== '0') {
            if (formGroup.controls['overrideRate'].value !== '') {
              const rate = formGroup.controls['overrideRate'].value;
              formGroup.controls['billedRate'].setValue(rate);
            }
          }
        } else {
          formGroup.controls['billedRate'].setValue(0);
        }
      }
    } else {
    }
    if (value === 0) {
      formGroup.controls['billedRate'].setValue(value);
    }
    let quantity = formGroup.controls['quantity'].value;
    const multiplier = formGroup.controls['multiplier'].value;

    quantity = quantity * multiplier;
    let billRate: number;
    if (formGroup.controls['billedRate']) {
      billRate = formGroup.controls['billedRate'].value;
    } else {
      billRate = 0;
    }
    const cost = quantity * billRate;
    if (quantity !== 0) {
      formGroup.controls['estimatiedPrice'].setValue(cost);
    } else {
      formGroup.controls['estimatiedPrice'].setValue(0);
    }
    this.calculateTotal();
  }
  calculateTotals() {
    if (this.serviceEstimateForm.valid) {
      const formValue = this.calculateTotal();
      formValue.phaseItems
        .filter((x: any) => !x.id)
        .forEach((item: any) => {
          delete item.id;
        });
      if (!this.secId) {
        this.psService
          .createSeComponent(formValue)
          .pipe(
            tap((e) => {
              this.isSaving = true;
              return e;
            })
          )
          .subscribe(
            (res) => {
              this.location.back();
            },
            (error) => {}
          );
      } else {
        this.psService
          .updateSeComponent(formValue)
          .pipe(
            tap((e) => {
              this.isSaving = true;
              return e;
            })
          )
          .subscribe(
            (res) => {
              this.location.back();
            },
            (error) => {}
          );
      }
    } else {
      this.isValid = false;
      this.serviceEstimateForm.markAllAsTouched();
    }
  }
  openUpdateChangesDialog() {
    const dialogRef = this.dialog.open(ConfirmComponentChangesComponent, {
      width: '475px',
      height: '175px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.calculateTotals();
      }
    });
  }

  calculateTotal() {
    // if (this.serviceEstimateForm.valid) {
    this.isValid = true;
    this.totalPrevailingWageHrs = 0;
    this.totalTravelExpenseHrs = 0;
    this.totalBPHrs = 0;
    let total: number;
    total = 0;
    let totalHours: number;
    totalHours = 0;
    const closingItems: Array<any> = this.serviceEstimateDetailsClosing.value;
    const executeItems: Array<any> = this.serviceEstimateDetailsExecute.value;
    const planningItems: Array<any> = this.serviceEstimateDetailsPlanning.value;
    const businessPartnerItems: Array<any> = this.businessPartnerItems.value;
    // const businessItems: Array<any> = this.businessPartnerItems.value;
    let businessPartnerTotal = 0;
    businessPartnerItems.forEach((i) => {
      if (!this.isFixedFeeSE()) {
        this.totalBPHrs += i['estimatedHours'];
        total +=
          Number.parseInt(Number.parseInt(i['estimatedHours']).toFixed(2)) *
          Number.parseInt(Number.parseInt(i['sell']).toFixed(2));
      } else {
        total += Number.parseInt(Number.parseInt(i['sell']).toFixed(2));
      }
      businessPartnerTotal += i.sell;
    });
    closingItems.forEach((item) => {
      totalHours += item['quantity'] * item['multiplier'];
      total += Number.parseInt(
        Number.parseInt(item['estimatiedPrice']).toFixed(2)
      );
      if (item.isPrevailingWage) {
        this.totalPrevailingWageHrs += item['quantity'] * item['multiplier'];
      }
    });
    executeItems.forEach((item) => {
      totalHours += item['quantity'] * item['multiplier'];
      total += Number.parseInt(
        Number.parseInt(item['estimatiedPrice']).toFixed(2)
      );
      if (item.isPrevailingWage) {
        this.totalPrevailingWageHrs += item['quantity'] * item['multiplier'];
      }
    });
    planningItems.forEach((item) => {
      totalHours += item['quantity'] * item['multiplier'];
      total += Number.parseInt(
        Number.parseInt(item['estimatiedPrice']).toFixed(2)
      );
      if (item.isPrevailingWage) {
        this.totalPrevailingWageHrs += item['quantity'] * item['multiplier'];
      }
    });
    const priceAdjustment = Number.parseInt(
      this.serviceEstimateForm.controls['priceAdjustment'].value
    );
    if (priceAdjustment) {
      total += priceAdjustment;
    }
    total += this.getTravelTotal();
    total += this.getMaterialTotal();
    this.serviceEstimateForm.controls['totalAmount'].setValue(total);
    this.serviceEstimateForm.controls['totalHours'].setValue(totalHours);
    let formValue: any;
    this.serviceEstimateForm.controls['businessPartnerTotal'].setValue(
      businessPartnerTotal
    );
    formValue = this.serviceEstimateForm.value;
    formValue['phaseItems'] = [];
    closingItems.forEach((item) => {
      formValue['phaseItems'].push(item);
    });
    executeItems.forEach((item) => {
      formValue['phaseItems'].push(item);
    });
    planningItems.forEach((item) => {
      formValue['phaseItems'].push(item);
    });
    return formValue;
    // }
  }
  calculateMaterialTotal() {
    const matItems: Array<any> = this.materialItems.value;
    let total = 0;
    matItems.forEach((item) => {
      total += item['sell'];
    });
    const totalValue = this.serviceEstimateForm.controls['totalAmount'].value;

    this.serviceEstimateForm.controls['totalAmount'].setValue(
      totalValue + total
    );
  }

  getMaterialTotal() {
    const matItems: Array<any> = this.materialItems.value;
    let total = 0;
    matItems.forEach((item) => {
      total += item['sell'];
    });
    return total;
  }

  getTravelTotal() {
    const travelExpenses: Array<any> = this.travelExpenses.value;
    let total = 0;
    travelExpenses.forEach((item) => {
      total += item['airfare'];
      total += item['auto'];
      total += item['hotel'];
      total += item['gas'];
      total += item['meals'];
      total += item['other'];
      total += item['travelHours'] * item['perHourCharge'];
      this.totalTravelExpenseHrs += item['travelHours'];
    });
    return total;
  }
  removeControl(control: any, index: any) {
    const id = this.businessPartnerItems.controls[index].value['id'];
    if (id) {
      this.serviceEstimatiorService
        .deleteBusinessPartnerItemById(id)
        .subscribe((res) => {
          this.businessPartnerItems.removeAt(index);
          this.setGrossMarginValidations(true);
          this.setUpliftValidations(true);
          this.setTotalGrossMarginPercentage();
          this.setTotalUpliftPercentage();
        });
    } else {
      this.businessPartnerItems.removeAt(index);
      this.setGrossMarginValidations(true);
      this.setUpliftValidations(true);
      this.setTotalGrossMarginPercentage();
      this.setTotalUpliftPercentage();
    }
  }
  removeTEControl(control: any, index: any) {
    const id = this.travelExpenses.controls[index].value['id'];
    if (id) {
      this.serviceEstimatiorService
        .deleteTravelExpenseItem(id)
        .subscribe((res) => {
          this.travelExpenses.removeAt(index);
        });
    } else {
      this.travelExpenses.removeAt(index);
    }
  }
  //}
  openBusinessSearch(control: any, index: any) {
    const popup = this.dialog.open(BusinessPartnerSearchPopupComponent, {
      height: '60%',
      width: '60%',
      data: {
        client: this.serviceEstimate.serviceProject.client,
      },
    });
    popup.afterClosed().subscribe((res) => {
      if (res) {
        let item: any;
        item = this.businessPartnerItems.at(index).value;
        this.businessPartnerItems.at(index).patchValue({
          bpName: res.data.businessPartnerLegalName,
          businessPartnerId: res.data.id,
        });
      }
    });
  }

  public canDeactivate(): boolean {
    let state: boolean;
    state = true;
    if (this.form) {
      state = this.form.submitted || !this.form.dirty;
    }

    const url = this.location.path();

    if (state) {
    } else {
      history.pushState(null, 'Meridian Unified Portal', url);
    }
    return state;
  }
  private getBusinessPartnerTotal() {
    let totalHours: any;
    let total: any;
    total = 0;
    totalHours = 0;

    const businessPartnerItems: Array<any> = this.businessPartnerItems.value;
    businessPartnerItems.forEach((i) => {
      if (!this.isFixedFeeSE()) {
        totalHours += Number.parseInt(
          Number.parseInt(i['estimatedHours']).toFixed(2)
        );
        const quanity = Number.parseInt(i['estimatedHours']).toFixed(2);
        const sell = Number.parseInt(i['sell']).toFixed(2);
        total += Number.parseInt(quanity) * Number.parseInt(sell);
      } else {
        total += Number.parseInt(Number.parseInt(i['sell']).toFixed(2));
      }
    });
    return { totalHours, total };
  }
  private getPhaseItemsTotal() {
    let totalHours: any;
    let total: any;
    total = 0;
    totalHours = 0;
    const closingItems: Array<any> = this.serviceEstimateDetailsClosing.value;
    const executeItems: Array<any> = this.serviceEstimateDetailsExecute.value;
    const planningItems: Array<any> = this.serviceEstimateDetailsPlanning.value;

    closingItems.forEach((item) => {
      totalHours += item['quantity'] * item['multiplier'];
      total += Number.parseInt(item['estimatiedPrice']);
    });
    executeItems.forEach((item) => {
      totalHours += item['quantity'] * item['multiplier'];
      total += Number.parseInt(item['estimatiedPrice']);
    });
    planningItems.forEach((item) => {
      totalHours += item['quantity'] * item['multiplier'];
      total += Number.parseInt(item['estimatiedPrice']);
    });
    return { totalHours, total };
  }
  private getTravelTotals() {
    const travelExpenses: Array<any> = this.travelExpenses.value;
    let total: any;
    total = 0;
    travelExpenses.forEach((item) => {
      total += item['airfare'];
      total += item['auto'];
      total += item['hotel'];
      total += item['gas'];
      total += item['meals'];
      total += item['other'];
      total += item['travelHours'] * item['perHourCharge'];
    });

    return total;
  }
  private getSkuTotal() {
    const skus: Array<any> = this.skuItems.value;
    let total: any;
    total = 0;
    skus.forEach((item) => {
      total += item['cost'];
    });
    return total;
  }
  private getMaterialItemsTotal() {
    let total: any;
    total = 0;
    this.materialItems.controls.forEach((c) => {
      if (c) {
        var sellItem = c.get('sell');
        if (sellItem) {
          const sellValue = sellItem.value;
          total += Number.parseInt(sellValue);
        }
      }
    });
    return total;
  }
  calculateTotalsForView(data?: any) {
    let totalHours;
    let total;
    total = 0;
    totalHours = 0;
    this.totalBPHrs = 0;
    const priceAdjustment = data
      ? data['priceAdjustment']
      : this.serviceEstimateForm
      ? this.serviceEstimateForm.controls['priceAdjustment'].value
      : 0;
    if (priceAdjustment) {
      total += Number.parseInt(Number.parseInt(priceAdjustment).toFixed(2));
    }
    total += this.getMaterialItemsTotal();
    total += this.getTravelTotals();
    total += this.getPhaseItemsTotal().total;
    this.totalBPHrs = this.getBusinessPartnerTotal().total;
    totalHours += this.getPhaseItemsTotal().totalHours;
    totalHours += this.getBusinessPartnerTotal().totalHours;

    return { total, totalHours };
  }
  createTemplate() {
    this.psService.cloneToTemplate(this.secId).subscribe(
      (res) => {
        this._snackBar.open('Template created successfully', 'dismiss', {
          duration: 5000,
        });
      },
      (error) => {
        this._snackBar.open(
          'There was a problem creating the template. Possible non-unique name',
          'dismiss',
          {
            duration: 6000,
          }
        );
      }
    );
  }
  moveSEcomponent() {
    const dialogref = this.dialog.open(ServiceEstimatePopupComponent, {
      width: '100%',
      height: '500px',
      data: { seId: this.seId },
    });
    dialogref.afterClosed().subscribe((res) => {
      if (res) {
        this.psService
          .moveComponentToSE(res['data'], this.secId)
          .subscribe((res) => {
            this.location.back();
          });
      }
    });
  }
  viewHistory() {
    const dialogref = this.dialog.open(ServiceEstimateComponentPopupComponent, {
      width: '100%',
      height: '500px',
      data: {
        secId: this.secId,
        componentName: this.sec.componentName,
        createdBy: this.sec.createdByEmployeeId,
        createdDate: this.sec.createdDate,
      },
    });
    dialogref.afterClosed().subscribe((res) => {
      if (res) {
        this.location.back();
      }
    });
  }
  itemsReordered(data: any) {
    this.serviceEstimateDetailsPlanning.setValue(data);
  }
  drop(event: any) {
    let item;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      this.reorderPhaseIndex(event.container.data);
      switch (event.container.id) {
        case 'planItems':
          this.serviceEstimateDetailsPlanning.setValue(event.container.data);
          break;
        case 'executeItems':
          this.serviceEstimateDetailsExecute.setValue(event.container.data);
          break;
        case 'closeItems':
          this.serviceEstimateDetailsClosing.setValue(event.container.data);
          break;
      }
    } else {
      item = event.previousContainer.data[event.previousIndex];
      this.reorderItems(event, item);
    }
  }
  reorderPhaseIndex(items: any) {
    items.forEach((x: any, index: any) => {
      x.phaseItemOrder = index;
    });
  }
  updatePhaseIndex(phase: ProjectPhase) {
    let items: UntypedFormArray;
    if (phase == ProjectPhase.Plan) {
      items = this.serviceEstimateDetailsPlanning;
      this.reorderPhaseIndex(items.value);
      this.serviceEstimateDetailsPlanning.setValue(items.value);
    } else if (phase == ProjectPhase.Execute) {
      items = this.serviceEstimateDetailsExecute;
      this.reorderPhaseIndex(items.value);
      this.serviceEstimateDetailsExecute.setValue(items.value);
    } else {
      items = this.serviceEstimateDetailsClosing;
      this.reorderPhaseIndex(items.value);
      this.serviceEstimateDetailsClosing.setValue(items.value);
    }
  }
  reorderItems(event: any, item: any) {
    let items;
    let prevItems;
    let isNewRecord;
    isNewRecord = !this.secId;
    switch (event.previousContainer.id) {
      case 'planItems':
        prevItems = this.serviceEstimateDetailsPlanning;
        prevItems.removeAt(event.previousIndex);
        this.reorderPhaseIndex(prevItems.value);
        this.serviceEstimateDetailsPlanning.setValue(prevItems.value);
        break;
      case 'executeItems':
        prevItems = this.serviceEstimateDetailsExecute;
        prevItems.removeAt(event.previousIndex);
        this.reorderPhaseIndex(prevItems.value);
        this.serviceEstimateDetailsExecute.setValue(prevItems.value);
        break;
      case 'closeItems':
        prevItems = this.serviceEstimateDetailsClosing;
        prevItems.removeAt(event.previousIndex);
        this.reorderPhaseIndex(prevItems.value);
        this.serviceEstimateDetailsClosing.setValue(prevItems.value);
        break;
    }
    switch (event.container.id) {
      case 'planItems':
        this.addItem(ProjectPhase.Plan, item, isNewRecord);
        items = this.swap(this.serviceEstimateDetailsPlanning.value, event);
        this.reorderPhaseIndex(items);
        this.serviceEstimateDetailsPlanning.setValue(items);
        break;
      case 'executeItems':
        this.addItem(ProjectPhase.Execute, item, isNewRecord);
        items = this.swap(this.serviceEstimateDetailsExecute.value, event);
        this.reorderPhaseIndex(items);
        this.serviceEstimateDetailsExecute.setValue(items);
        break;
      case 'closeItems':
        this.addItem(ProjectPhase.Close, item, isNewRecord);
        items = this.swap(this.serviceEstimateDetailsClosing.value, event);
        this.reorderPhaseIndex(items);
        this.serviceEstimateDetailsClosing.setValue(items);
        break;
    }
  }
  swap(items: any, event: any) {
    let temp = items[event.currentIndex];
    items[event.currentIndex] = items[items.length - 1];
    items[items.length - 1] = temp;
    return items;
  }
  roundToNearestHalfhr(value: any) {
    if (value) return (Math.ceil(value / 0.25) * 0.25).toFixed(2);
    else return value;
  }
  _filter(value: any): Tag[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.actualTags
      ?.filter((x) => x.name.toLowerCase().includes(filterValue))
      .sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
  }
  toggleSelect() {
    if (!this.isSelectOpened) {
      this.tagComponent.select.openPanel();
    }
    this.isSelectOpened = !this.isSelectOpened;
  }
  toggleSelectOpened() {
    this.isSelectOpened = false;
  }
  tagSelected(tag: Tag) {
    this.addTagItem(tag, true);
    this.toggleSelectOpened();
  }
  getTagName(id: string) {
    return this.tags.find((tag) => tag.id == id)?.name;
  }
  openTagPopup(tagId: string) {
    const popup = this.dialog.open(TagsPopupComponent, {
      height: '60%',
      minWidth: '60%',
      panelClass: 'no-padding',
      data: { tagId: tagId },
    });
    popup.afterClosed().subscribe((selectedPopupValue) => {});
  }

  IsNotValidValidator(grossMarginControl: AbstractControl) {
    if (
      grossMarginControl.value != null &&
      grossMarginControl.value.toFixed(2) < 15 &&
      !this.serviceEstimate?.isLocked
    ) {
      return { isNotValid: { value: grossMarginControl.value } };
    }
    return null;
  }

  getEngagementType() {
    let engagementType =
      this.serviceEstimateForm.controls['engagementType'].value;
    switch (engagementType) {
      case 0:
      case 1:
      case 2:
        return this.engagementType[engagementType];
        break;
      default:
        return null;
    }
  }

  getServiceRole() {
    let serviceRoleId =
      this.serviceEstimateForm.controls['serviceRoleId'].value;
    let serviceRole = this.serviceRoles.find(
      (x) => x.serviceRoleId == serviceRoleId
    );
    return serviceRole ? serviceRole.serviceRoleName : null;
  }

  getSolutionPilar(item: UntypedFormGroup) {
    let pilarId = item.controls['solutionPilarId'].value;
    let solPilar = this.solutionPilars.find(
      (x) => x.solutionPilarId == pilarId
    );
    return solPilar ? solPilar.solutionPilarName : null;
  }

  isFixedFeeValidation() {
    return (
      this.isFixedFeeSE() &&
      this.businessPartners?.some((x: any) => !x.isFixedCost)
    );
  }

  isFixedFeeSE() {
    return this.serviceEstimateType == 0;
  }

  onServiceRoleChange(serviceRoleId: string) {
    this.selectedServiceRole = this.serviceRoles.find(sr => sr.serviceRoleId === serviceRoleId);
    // this.serviceEstimateForm.controls['componentOverrideRate'].setValue(null);
  }

}
