import { Component, OnInit, Input, Output } from '@angular/core';
import { ProjectsDataSource } from '../../models/professional-services-projects.datasource';
import { ProfessionalServicesService } from '../../professional-services.service';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDeleteDialogComponent } from '../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ContractSignedDateDialogComponent } from '../../../shared/components/contract-signed-date-dialog/contract-signed-date-dialog.component';
import { forkJoin } from 'rxjs';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { RoleService } from '../../../shared/services/role.service';
import { MilestoneSummaryPopupComponent } from '../milestone-summary-popup/milestone-summary-popup.component';
import { CloningPopupComponent } from '../../../shared/components/cloning-popup/cloning-popup.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ExportQuotePopupComponent } from '../export-quote-popup/export-quote-popup.component';
import { ComponentDetailsPopupComponent } from '../component-details-popup/component-details-popup.component';

@Component({
  selector: 'app-professional-services-estimate-list',
  templateUrl: './professional-services-estimate-list.component.html',
  styleUrls: ['./professional-services-estimate-list.component.scss']
})
export class ProfessionalServicesEstimateListComponent implements OnInit {
  @Input() serviceEstimates: Array<any>;
  @Input() changeOrders: Array<any>;
  @Output() seCount: number;
  displayedColumns: Array<string>;
  dataSource: ProjectsDataSource;
  changeOrderDataSource: ProjectsDataSource;
  seId: string;
  project: any;
  isSales: boolean;
  showDeleteAction: boolean;
  user: AccountInfo | null;
  isAbleToSignContract: boolean;
  isAbleToUpdateContractDate: boolean;
  approversList: any[];
  isLoading: boolean;
  isNameAscending: boolean;
  isCONameAscending: boolean;
  dialogRef: MatDialogRef<any>;
  constructor(private projectService: ProfessionalServicesService, private route: ActivatedRoute, public dialog: MatDialog, private authService: MsalService, private roleService: RoleService) { }

  ngOnInit() {
    this.isLoading = true;
    this.isNameAscending = true;
    this.isCONameAscending = true;
    this.isAbleToUpdateContractDate = false;
    this.isAbleToSignContract = false;

    this.user = this.authService.instance.getActiveAccount();

    if (this.hasRole('PortalSEServiceOperations')) {
      this.isAbleToSignContract = true;
    }
    if (this.hasRole('PortalAdmin')) {
      this.isAbleToSignContract = true;
      this.isAbleToUpdateContractDate = true;
    }
    if (this.hasRole('PortalSEDirector')) {
      this.isAbleToUpdateContractDate = true;
    }
    if (this.hasRole('projects.approver')) {
      this.isAbleToUpdateContractDate = true;
    }
    if (this.hasRole('ProfessionalServiceCoordinator')) {
      this.isAbleToSignContract = true;
    }

    this.isSales = this.hasRole('PortalSEPostsalesEngineer');
    this.showDeleteAction = this.hasRole('PortalSEDirector') || this.hasRole('PortalAdmin') || this.hasRole('PortalSEPresalesArchitect') ||
      this.hasRole('PortalSEProjectManager') || this.hasRole('PortalSEProjectManagerOfficer') || this.hasRole('projects.approver');
      this.displayedColumns = [
        'projectName',
        'updatedDate',
        'createdDate',
        'approvedDate',
        'mileStone',
        'status',
        'seApprover', // Add SE Approver here
        'expirationDate',
        'componentDetails',
        'tags',
        'actions'
      ];
    this.route.params.subscribe(param => {
      this.seId = param['projectId'];
      if (!this.seId) {
        this.route.parent?.parent?.params.subscribe(param2 => {
          this.seId = param2['id'];
          this.projectService.getCloudMigrationServiceProject(this.seId)
            .subscribe(projectResult => {
              this.project = projectResult;
            });
          this.getTableData();
        });
      }
      else {
        this.projectService.getProject(this.seId)
          .subscribe(projectResult => {
            this.project = projectResult;
          });
        this.getTableData();
      }
    });
  }
  getTableData() {

    let fetchApprovers = this.projectService.getSEApproversByProjectId(this.seId);
    let fetchProjSEs = this.projectService.getSEsByProjectId(this.seId);
    forkJoin([fetchProjSEs, fetchApprovers]).subscribe(results => {
      let data = results[0];
      this.approversList = results[1];
      data.forEach(prop => {
        this.setApprover(prop);
      });
      this.serviceEstimates = data.filter(se => se.isChangeOrder == false);
      this.changeOrders = data.filter(se => se.isChangeOrder == true);
      this.seCount = this.serviceEstimates.length;
      this.setTableData();
      this.setChangeOrderTableData(this.changeOrders);
    }, (error) => {

    }, () => {
      this.isLoading = false;
    });

  }
  setApprover(prop: any) {
    var approverItem: any = this.approversList.find(a => a.serviceEstimateId == prop.serviceEstimateId);
    var approverNameList: Array<string> = approverItem?.approvers.map((approver: any) => approver ? approver.firstName + " " + approver.lastName : '') ?? [];
    var uniqueApprovers = [...new Set(approverNameList)];
    var approvers = uniqueApprovers.join(', ');
    prop['approvers'] = approvers;
  }
  setTableData(data?: Array<any>) {
    console.log(this.serviceEstimates);
    this.dataSource = new ProjectsDataSource();
    this.dataSource.setData(this.serviceEstimates);
  }
  setChangeOrderTableData(data?: Array<any>) {
    this.changeOrderDataSource = new ProjectsDataSource();
    this.changeOrderDataSource.setData(data);
  }
  deleteRecord(id: string) {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '475px',
      height: '175px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {

          this.projectService.deleteSE(id)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });
  }

  markContractSigned(element: any) {
    const dialogRef = this.dialog.open(ContractSignedDateDialogComponent, {
      width: '475px',
      height: '275px'
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          element['contractSignedDate'] = result['contractSignDate'];
          let { serviceEstimateTags, ...serviceEstimate } = element;//ignore child entity object for se update
          if (element.serviceEstimateStatus === 4) {
            this.projectService.updateContractSignedDate(serviceEstimate)
              .subscribe(res => {
                this.getTableData();
              })
          } else {
            this.projectService.markContractSigned(serviceEstimate)
              .subscribe(res => {
                this.getTableData();

              });
          }
        }
      });
  }
  fetchSEMilestones(milestoneVal: number) {
    this.dialog.open(MilestoneSummaryPopupComponent, {
      width: '100%',
      height: '500px',
      data: { milestoneVal: milestoneVal, project: this.project }
    });
  }
  sortByMilestone() {
    this.isNameAscending = !this.isNameAscending;
    if (this.isNameAscending) {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.milestone;
        const nameTwo = b.milestone;
        if (nameOne < nameTwo) { return -1; }
        if (nameOne > nameTwo) { return 1; }
        return 1;
      }));
    } else {
      this.setTableData(this.serviceEstimates.sort((a, b) => {
        const nameOne = a.milestone;
        const nameTwo = b.milestone;
        if (nameOne < nameTwo) { return 1; }
        if (nameOne > nameTwo) { return -1; }
        return 1;
      }));
    }
  }
  changeOrderSortByMilestone() {
    this.isCONameAscending = !this.isCONameAscending;
    if (this.isCONameAscending) {
      this.setChangeOrderTableData(this.changeOrders.sort((a, b) => {
        const nameOne = a.milestone;
        const nameTwo = b.milestone;
        if (nameOne < nameTwo) { return -1; }
        if (nameOne > nameTwo) { return 1; }
        return 1;
      }));
    } else {
      this.setChangeOrderTableData(this.changeOrders.sort((a, b) => {
        const nameOne = a.milestone;
        const nameTwo = b.milestone;
        if (nameOne < nameTwo) { return 1; }
        if (nameOne > nameTwo) { return -1; }
        return 1;
      }));
    }
  }
  cloneRecord(id: string, isChangeOrder?: boolean) {
    const dialogRef = this.dialog.open(CloningPopupComponent, {
      width: '30%',
      height: '35%',
      data: { popupFor: isChangeOrder ? "Change Order" : "Service Estimate" }
    });
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result) {
          this.projectService.cloneSE(id, result.clonedName)
            .subscribe(res => {
              this.getTableData();

            });
        }
      });

  }
  hasRole(role: string) {
    return this.roleService.checkRoleExists(role, this.user, false);
  }

  openQuoteExportPopup() {
    this.dialogRef = this.dialog.open(ExportQuotePopupComponent, {
      height: '70%',
      width: '80%',
      autoFocus: false,
      id: 'exportQuoteModal',
      data: {
        serviceEstimates: this.serviceEstimates,
        changeOrders: this.changeOrders
      }
    });
  }

  openComponentDetailsPopup(element: any): void {
    console.log('Opening component details popup for:', element.serviceEstimateId);
    const dialogRef = this.dialog.open(ComponentDetailsPopupComponent, {
      width: '1200px', // You can adjust the width of the popup
      data: { seId: element.serviceEstimateId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // Handle any actions after the dialog is closed, if necessary
    });
  }
}
