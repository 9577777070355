
<mat-card>
  <mat-card-content>
    <nav mat-tab-nav-bar>
      <a mat-tab-link [routerLink]="['serviceRoles']" routerLinkActive="active-link" id="serviceRolesTab">Service Roles</a>
      <a mat-tab-link [routerLink]="['projectControls']" routerLinkActive="active-link" id="projectControlsTab">Project Control</a>
      <a mat-tab-link [routerLink]="['projectControlRates']" routerLinkActive="active-link" id="projectControlRatesTab">Project Control Rate</a>
      <a mat-tab-link [routerLink]="['approverThreshold']" routerLinkActive="active-link" id="approverThresholdTab">Threshold</a>
      <a mat-tab-link [routerLink]="['tempApprover']" routerLinkActive="active-link" id="tempApproverTab">Temporary Approvers</a>
      <a mat-tab-link [routerLink]="['marginAdjustments']" routerLinkActive="active-link" id="marginAdjustmentsTab">Margin Adjustments</a>
    </nav>
    <router-outlet></router-outlet>
  </mat-card-content>
</mat-card>
