import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { EmployeeComponent } from './employee/employee.component';
import { RoleComponent } from './role/role.component';
import { EmployeeListComponent } from './employee/employee-list/employee-list.component';
import { EmployeeCreateComponent } from './employee/employee-create/employee-create.component';
import { EmployeeDetailsComponent } from './employee/employee-details/employee-details.component';
import { RoleListComponent } from './role/role-list/role-list.component';
import { RoleCreateComponent } from './role/role-create/role-create.component';
import { RoleDetailsComponent } from './role/role-details/role-details.component';


import { ExpenseCardComponent } from './expense-card/expense-card.component';
import { CreateCardComponent } from './expense-card/create-card/create-card.component';
import { ListCardComponent } from './expense-card/list-card/list-card.component';
import { DetailCardComponent } from './expense-card/detail-card/detail-card.component';

import { AdminLandingPageComponent } from './admin-landing-page/admin-landing-page.component';
import { ServiceRoleComponent } from './service-role/service-role.component';
import { ServiceRoleLandingComponent } from './service-role/service-role-landing/service-role-landing.component';
import { ServiceRoleCreateComponent } from './service-role/service-role-create/service-role-create.component';
import { ServiceRoleDetailComponent } from './service-role/service-role-detail/service-role-detail.component';
import { ServiceRoleEditComponent } from './service-role/service-role-edit/service-role-edit.component';
import { ProjectControlComponent } from './project-control/project-control.component';
import { ProjectControlLandingPageComponent } from './project-control/project-control-landing-page/project-control-landing-page.component';
import { ProjectControlDetailComponent } from './project-control/project-control-detail/project-control-detail.component';
import { ProjectControlUpdateComponent } from './project-control/project-control-update/project-control-update.component';
import { ProjectControlCreateComponent } from './project-control/project-control-create/project-control-create.component';
import { ProjectControlRateComponent } from './project-control-rate/project-control-rate.component';
import { ProjectControlRateLandingPageComponent } from './project-control-rate/project-control-rate-landing-page/project-control-rate-landing-page.component';
import { ProjectControlRateDetailComponent } from './project-control-rate/project-control-rate-detail/project-control-rate-detail.component';
import { ProjectControlRateUpdateComponent } from './project-control-rate/project-control-rate-update/project-control-rate-update.component';
import { ProjectControlRateCreateComponent } from './project-control-rate/project-control-rate-create/project-control-rate-create.component';
import { VehiclemanagerLandingPageComponent } from './vehicle-manager/vehiclemanager-landing-page/vehiclemanager-landing-page.component';
import { VehicleManagerComponent } from './vehicle-manager/vehicle-manager.component';
import { VehicleManagerCreateComponent } from './vehicle-manager/vehicle-manager-create/vehicle-manager-create.component';
import { VehicleManagerUpdateComponent } from './vehicle-manager/vehicle-manager-update/vehicle-manager-update.component';
import { VehicleManagerDetailComponent } from './vehicle-manager/vehicle-manager-detail/vehicle-manager-detail.component';


import { LocationComponent } from './location/location.component';
import { StatesComponent } from './location/states/states.component';
import { CitiesComponent } from './location/cities/cities.component';
import { CountiesComponent } from './location/counties/counties.component';
import { StateListComponent } from './location/states/state-list/state-list.component';
import { CountyListComponent } from './location/counties/county-list/county-list.component';
import { CityListComponent } from './location/cities/city-list/city-list.component';
import { ApproverThresholdComponent } from './approver-threshold/approver-threshold.component';
import { ThresholdListComponent } from './approver-threshold/threshold-list/threshold-list.component';
import { ThresholdCreateComponent } from './approver-threshold/threshold-create/threshold-create.component';
import { CitiesTableComponent } from './location/cities/cities-table/cities-table.component';
import { InactiveEmployeeListComponent } from './employee/inactive-employee-list/inactive-employee-list.component';
import { TempApproverComponent } from './temp-approver/temp-approver.component';
import { TempApproverCreateComponent } from './temp-approver/temp-approver-create/temp-approver-create.component';
import { TempApproverListComponent } from './temp-approver/temp-approver-list/temp-approver-list.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import { ReleaseNotesListComponent } from './release-notes/release-notes-list/release-notes-list.component';
import { ReleaseNotesCreateComponent } from './release-notes/release-notes-create/release-notes-create.component';
import { RoleAdministrationComponent } from './role-administration/role-administration.component';
import { RoleAdminListComponent } from './role-administration/role-admin-list/role-admin-list.component';
import { RoleAdminCreateComponent } from './role-administration/role-admin-create/role-admin-create.component';
import { AdminNestedTabsComponent } from './admin-nested-tabs/admin-nested-tabs.component';
import { MarginAdjustmentsComponent } from './margin-adjustments/margin-adjustments.component';
import { MarginAdjustmentsListComponent } from './margin-adjustments/margin-adjustments-list/margin-adjustments-list.component';
import { MarginAdjustmentsCreateComponent } from './margin-adjustments/margin-adjustments-create/margin-adjustments-create.component';



const routes: Routes = [
  {
    path: 'admin', component: AdminComponent, data: {
      breadcrumb: 'Admin', isRoutable: true
    }, children:
      [
        { path: '', pathMatch: 'full', redirectTo: 'projects' },
        {
          path: 'employees', component: EmployeeComponent, children: [
            { path: '', component: EmployeeListComponent },
            { path: 'create', component: EmployeeCreateComponent },
            { path: 'details/:id', component: EmployeeDetailsComponent }
          ]
        },
        {
          path: 'inactiveEmployees', component: InactiveEmployeeListComponent
        },
        {
          path: 'roles', component: RoleComponent, children: [
            { path: '', component: RoleListComponent },
            { path: 'create', component: RoleCreateComponent },
            { path: 'details/:id', component: RoleDetailsComponent }
          ]
        },
        {
          path: 'cards', component: ExpenseCardComponent, children: [
            { path: '', component: ListCardComponent },
            { path: 'create', component: CreateCardComponent },
            { path: 'details/:id', component: DetailCardComponent }
          ]
        },
        {
          path: 'projects', component: AdminNestedTabsComponent, data: {
            breadcrumb: 'Projects', isRoutable: true
          }, children: [
            {
              path: '', redirectTo: 'serviceRoles', pathMatch: 'full'
            },
            {
              path: 'serviceRoles', component: ServiceRoleComponent, data: {
                breadcrumb: 'Service Roles', isRoutable: true
              }, children: [
                {
                  path: '', component: ServiceRoleLandingComponent, data: {
                    breadcrumb: null, isRoutable: false
                  }
                },
                {
                  path: 'create', component: ServiceRoleCreateComponent, data: {
                    breadcrumb: 'Create Service Role', isRoutable: true
                  }
                },
                {
                  path: 'detail/:id', component: ServiceRoleDetailComponent
                },
                {
                  path: 'edit/:id', component: ServiceRoleEditComponent, data: {
                    breadcrumb: 'Edit Service Role', isRoutable: true
                  }
                }
              ]
            },
            {
              path: 'projectControls', component: ProjectControlComponent, data: {
                breadcrumb: 'Project Control', isRoutable: true
              }, children: [
                {
                  path: '', component: ProjectControlLandingPageComponent, data: {
                    breadcrumb: null, isRoutable: false
                  }
                },
                {
                  path: 'create', component: ProjectControlCreateComponent, data: {
                    breadcrumb: 'Create Project Control', isRoutable: true
                  }
                },
                { path: 'detail/:id', component: ProjectControlDetailComponent },
                {
                  path: 'edit/:id', component: ProjectControlUpdateComponent, data: {
                    breadcrumb: 'Edit Project Control', isRoutable: true
                  }
                }
              ]
            },
            {
              path: 'projectControlRates', component: ProjectControlRateComponent, data: {
                breadcrumb: 'Project Control Rate', isRoutable: true
              }, children: [
                {
                  path: '', component: ProjectControlRateLandingPageComponent, data: {
                    breadcrumb: null, isRoutable: false
                  }
                },
                {
                  path: 'create', component: ProjectControlRateCreateComponent, data: {
                    breadcrumb: 'Create Project Control Rate', isRoutable: true
                  }
                },
                { path: 'detail/:id', component: ProjectControlRateDetailComponent },
                {
                  path: 'edit/:id', component: ProjectControlRateUpdateComponent, data: {
                    breadcrumb: 'Edit Project Control Rate', isRoutable: true
                  }
                }
              ]
            }, {
              path: 'approverThreshold', component: ApproverThresholdComponent, data: {
                breadcrumb: 'Threshold', isRoutable: true
              }, children: [
                {
                  path: '', component: ThresholdListComponent, data: {
                    breadcrumb: null, isRoutable: false
                  }
                },
                {
                  path: 'create', component: ThresholdCreateComponent, data: {
                    breadcrumb: 'Create Threshold', isRoutable: true
                  }
                },
                {
                  path: 'edit/:id', component: ThresholdCreateComponent, data: {
                    breadcrumb: 'Edit Threshold', isRoutable: true
                  }
                }
              ]
            },
            {
              path: 'tempApprover', component: TempApproverComponent, data: {
                breadcrumb: 'Temporary Approver', isRoutable: true
              }, children: [
                {
                  path: '', component: TempApproverListComponent, data: {
                    breadcrumb: null, isRoutable: false
                  }
                },
                {
                  path: 'create', component: TempApproverCreateComponent, data: {
                    breadcrumb: 'Create Temporary Approver', isRoutable: true
                  }
                },
                {
                  path: 'edit/:id', component: TempApproverCreateComponent, data: {
                    breadcrumb: 'Edit Temporary Approver', isRoutable: true
                  }
                }
              ]
            },{
              path: 'marginAdjustments', component: MarginAdjustmentsComponent, data: {
                breadcrumb: 'Margin Adjustments', isRoutable: true
              }, children: [
                {
                  path: '', component: MarginAdjustmentsCreateComponent, data: {
                    breadcrumb: null, isRoutable: false
                  }
                }
              ]
            }]
        },
        {
          path: 'vehicleManagers', component: VehicleManagerComponent, children: [
            { path: '', component: VehiclemanagerLandingPageComponent },
            { path: 'create', component: VehicleManagerCreateComponent },
            { path: 'edit/:id', component: VehicleManagerUpdateComponent },
            { path: 'detail/:id', component: VehicleManagerDetailComponent }
          ]
        },
        // {
        //   path: 'locations', component: LocationComponent, children: [
        //     {
        //       path: 'states', component: StatesComponent, children: [
        //         { path: '', component: StateListComponent }
        //       ]
        //     },
        //     {
        //       path: 'cities', component: CitiesComponent, children: [
        //         { path: '', component: CitiesTableComponent }
        //       ]
        //     },
        //     {
        //       path: 'counties', component: CountiesComponent, children: [
        //         { path: '', component: CountyListComponent }
        //       ]
        //     },
        //   ]
        // },       
        
        {
          path: 'releaseNotes', component: ReleaseNotesComponent, data: {
            breadcrumb: 'Release Notes', isRoutable: true
          }, children: [
            {
              path: '', component: ReleaseNotesListComponent, data: {
                breadcrumb: null, isRoutable: false
              }
            },
            {
              path: 'create', component: ReleaseNotesCreateComponent, data: {
                breadcrumb: 'Create Release Notes', isRoutable: true
              }
            },
            {
              path: 'edit/:id', component: ReleaseNotesCreateComponent, data: {
                breadcrumb: 'Edit Release Notes', isRoutable: true
              }
            }
          ]
        },
        
        {
          path: 'appRoles', component: RoleAdministrationComponent, data: {
            breadcrumb: 'Role Administration', isRoutable: true
          }, children: [
            {
              path: '', component: RoleAdminListComponent, data: {
                breadcrumb: null, isRoutable: false
              }
            }, {
              path: 'create', component: RoleAdminCreateComponent, data: {
                breadcrumb: 'Create App Role', isRoutable: true
              }
            }, {
              path: 'edit/:id', component: RoleAdminCreateComponent, data: {
                breadcrumb: 'Edit App Role', isRoutable: true
              }
            }
          ]
        }
      ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
