import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MarginAdjustmentsService } from '../margin-adjustments.service';
import { MarginOptionType } from '../models/marginOption';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-margin-adjustments-create',
  templateUrl: './margin-adjustments-create.component.html',
  styleUrls: ['./margin-adjustments-create.component.scss']
})
export class MarginAdjustmentsCreateComponent implements OnInit {
  
  marginOptionsFormGroup: UntypedFormGroup;
  
  constructor(private fb: UntypedFormBuilder,
    private marginAdjustmentsService: MarginAdjustmentsService, private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
      this.getMarginOptions();
    }

    getMarginOptions(){
      this.marginAdjustmentsService.getMarginOptions()
        .subscribe((res: any) => {
          if(res != null)
          {
            this.marginOptionsFormGroup = this.fb.group({
              'marginOptionType': [res.marginOptionType == 0 ? 'uplift' : 'grossMargin', Validators.required],
              'upliftPercentage': [res.marginOptionType == 0 ? res.upliftPercentage : null],
              'grossMarginPercentage': [res.marginOptionType == 1 ? res.grossMarginPercentage : null]
            });
          }else{
            this.marginOptionsFormGroup = this.fb.group({
              'marginOptionType': [null, Validators.required],
              'upliftPercentage': [null],
              'grossMarginPercentage': [null]
            });
          }
          this.onMarginOptionChange();
        });
    }

    mapMarginOptionTypeStringToEnum(value: string): MarginOptionType {
      return value === 'uplift' ? MarginOptionType.Uplift : MarginOptionType.GrossMargin;
    }

    submitForm(){
      if (this.marginOptionsFormGroup.valid) {
        var formValue = this.marginOptionsFormGroup.value;
        formValue.marginOptionType = this.mapMarginOptionTypeStringToEnum(formValue.marginOptionType);
        this.marginAdjustmentsService.updateMarginOptions(formValue)
        .subscribe((res: any) => {
          this.getMarginOptions();
          this._snackBar.open("Your changes have been saved.", "Dismiss", {
            duration: 5000,
          });
        });
    }
    }
    onMarginOptionChange(){
      this.marginOptionsFormGroup.controls['marginOptionType'].valueChanges.subscribe((res: any) => {
        if(res == "upliftBPPercentage"){
          this.marginOptionsFormGroup.controls['upliftPercentage'].setValidators(Validators.required);
          this.marginOptionsFormGroup.controls['grossMarginPercentage'].patchValue(null);
          this.marginOptionsFormGroup.controls['grossMarginPercentage'].clearValidators();
        }else if(res == "grossMarginBPPercentage"){
          this.marginOptionsFormGroup.controls['grossMarginPercentage'].setValidators(Validators.required);
          this.marginOptionsFormGroup.controls['upliftPercentage'].patchValue(null);
          this.marginOptionsFormGroup.controls['upliftPercentage'].clearValidators();
        }
        this.marginOptionsFormGroup.controls['upliftPercentage'].updateValueAndValidity();
        this.marginOptionsFormGroup.controls['grossMarginPercentage'].updateValueAndValidity();
      });
    }
    goBack() {
      this.marginOptionsFormGroup.reset();
    }
}
