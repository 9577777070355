import { Component } from '@angular/core';

@Component({
  selector: 'app-margin-adjustments',
  templateUrl: './margin-adjustments.component.html',
  styleUrls: ['./margin-adjustments.component.scss']
})
export class MarginAdjustmentsComponent {

}
